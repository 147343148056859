<template>
  <v-dialog fullscreen hide-overlay transition="dialog-bottom-transition" :value="visible">
    <v-card>
      <v-toolbar dark color="blue darken-3">
        <v-btn icon @click="close()"> <v-icon>mdi-close</v-icon> </v-btn>
        <v-toolbar-title v-if="form.id">{{ $t('edit-tour') }}</v-toolbar-title>
        <v-toolbar-title v-else>{{ $t('new-tour') }}</v-toolbar-title> <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn text :loading="loading" @click="save()">{{ $t('save') }}</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-form v-model="formValid" ref="form">
        <v-container fluid class="pa-4">
          <v-row justify="space-between" class="mt-2">
            <v-col cols="12" md="4" lg="4">
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="form.data.startDate"
                    :label="$t('start-date')"
                    persistent-hint
                    required
                    readonly
                    disabled
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-menu
                    ref="datepicker2"
                    v-model="datepicker2"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="form.data.endDate"
                        :label="$t('end-date')"
                        persistent-hint
                        v-on="on"
                        readonly
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.data.endDate"
                      no-title
                      first-day-of-week="1"
                      :min="form.data.startDate"
                      @input="datepicker2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    :label="$t('distance')"
                    hint="km"
                    v-model="form.data.distance"
                    required
                    :rules="[rules.required, rules.numberOnly]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('tour-date')"
                    v-model="form.data.name"
                    required
                    :rules="[rules.required]"
                    class="pb-0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="form.data.accomodation"
                    :items="addresses"
                    :label="$t('accomodation')"
                    item-text="name"
                    item-value="id"
                    auto-select-first
                    clearable
                    hide-selected
                    multiple
                    class="pb-0"
                    :search-input.sync="searchTerm"
                    @change="searchTerm = ''"
                  >
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.data.name"></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="data.item.data.zip + ' ' + data.item.data.city + ', ' + data.item.data.address"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    dense
                    auto-grow
                    rows="1"
                    row-height="24"
                    :label="$t('accomodationNote')"
                    v-model="form.data.accomodationNote"
                    class="pt-3 pb-0"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    dense
                    auto-grow
                    rows="1"
                    row-height="24"
                    :label="$t('note')"
                    v-model="form.data.note"
                    class="pt-3 pb-0"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <v-participant-list :participants.sync="form.data.participants"></v-participant-list>
            </v-col>
            <v-col cols="12" md="7" lg="4">
              <v-list flat subheader three-line v-if="ungroupedEventsByDate.length > 0">
                <v-container fluid class="px-0 pb-4">{{ $t('available-events-to-add') }}</v-container>
                <v-list-item-group v-model="ungroupedEventsIndex" multiple active-class>
                  <div v-for="event in ungroupedEventsByDate" v-bind:key="event.id">
                    <v-list-item>
                      <template v-slot:default="{ active }">
                        <v-list-item-action> <v-checkbox :input-value="active"></v-checkbox> </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title class="valign-center mb-2">
                            <v-avatar left class="grey lighten-3 mr-2" size="24" v-if="event.eventType">{{
                              eventTypeById(event.eventType).shortName
                            }}</v-avatar>
                            <span>{{ event.startTime }}</span> <span v-if="event.endTime">-</span>
                            <span>{{ event.endTime }}</span>
                          </v-list-item-title>
                          <template v-if="addressById(event.addressId)">
                            <v-list-item-subtitle>{{ addressById(event.addressId)?.name }}</v-list-item-subtitle>
                            <v-list-item-subtitle
                              >{{ addressById(event.addressId)?.zip }} {{ addressById(event.addressId)?.city }},
                              {{ addressById(event.addressId)?.address }}</v-list-item-subtitle
                            >
                          </template>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                    <v-divider></v-divider>
                  </div>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import * as firebase from 'firebase';
import { userById, addressById } from '@/helpers/form.helper';
import { rules } from '../../helpers/validator-rules';
import { mask } from 'vue-the-mask';
import ParticipantList from '../list/ParticipantList.vue';
export default {
  name: 'GroupDialog',
  props: ['visible', 'id', 'startDate'],
  components: { 'v-participant-list': ParticipantList },
  directives: { mask },
  data() {
    return {
      loading: false,
      searchTerm: '',
      datepicker1: false,
      datepicker2: false,
      form: {
        id: null,
        data: {
          name: null,
          startDate: this.startDate,
          endDate: null,
          events: [],
          participants: [],
          distance: null,
          accomodation: [],
          accomodationNote: null,
          note: null,
        },
      },
      formValid: false,
      participantId: null,
      ungroupedEvents: [],
      ungroupedEventsIndex: [],
      eventsIndex: [],
      mask: { date: '####-##-##', time: '##:##' },
      rules,
    };
  },
  computed: {
    group() {
      return this.id && this.$store.getters['eventGroup/groupById'](this.id);
    },
    addresses() {
      return this.$store.getters['address/addresses'];
    },
    users() {
      return this.$store.getters['user/users'];
    },
    eventTypes() {
      return this.$store.getters['eventType/eventTypesInclDeleted'];
    },
    ungroupedEventsByDate() {
      return this.$store.getters['event/ungroupedEventsByDate'](this.startDate);
    },
  },
  methods: {
    save() {
      if (this.formValid) {
        this.loading = true;
        this.ungroupedEvents = this.ungroupedEventsIndex.map((i) => this.ungroupedEventsByDate[i].id);
        this.form.data.events = [...this.eventsIndex.map((i) => this.form.data.events[i]), ...this.ungroupedEvents];
        // if (
        //   this.form.data.participants.length == 5 &&
        //   this.form.data.events.length > 0 &&
        //   this.form.data.distance !== null &&
        //   this.form.data.distance !== 0
        // ) {
        //   this.form.data.clean = true;
        // } else {
        //   this.form.data.clean = false;
        // }
        if (this.form.id) {
          this.form.data.participants = this.form.data.participants.map((p) => {
            // eslint-disable-next-line no-unused-vars
            const { userName, roleOrder, ...user } = p;
            return user;
          });
          firebase
            .firestore()
            .collection('eventGroups')
            .doc(this.form.id)
            .set({ ...this.form.data, updateTime: new Date().toISOString() })
            .then(() => {
              this.loading = false;
              this.close();
            })
            .catch((error) => {
              this.loading = false;
              this.showSnackbar(error);
            });
        } else {
          firebase
            .firestore()
            .collection('eventGroups')
            .add({ ...this.form.data, createTime: new Date().toISOString(), updateTime: new Date().toISOString() })
            .then(() => {
              firebase.firestore().collection('stat').doc('collectionCount')
                .update({ eventGroups: firebase.firestore.FieldValue.increment(1) });

              this.loading = false;
              this.close();
            })
            .catch((error) => {
              this.loading = false;
              this.showSnackbar(error);
            });
        }
      } else {
        this.$refs.form.validate();
      }
    },
    close() {
      this.$emit('close');
    },
    userById(id) {
      return userById(this.users, id);
    },
    addressById(id) {
      return addressById(this.addresses, id);
    },
    showSnackbar(text) {
      this.$store.dispatch('setSnackbar', { text }, { root: true });
    },
    eventTypeById(id) {
      return this.eventTypes?.find((et) => et.id === id) ?? '?';
    },
  },
  created() {
    if (this.id && this.group) {
      const { id, ...data } = this.group;
      this.form.id = id;
      this.form.data = JSON.parse(JSON.stringify(data));
      this.eventsIndex = Array.from({ length: this.group.events.length }, (v, k) => k);
    }
  },
};
</script>
<style scoped>
.confirm-text {
  font-size: 1rem;
}
</style>
