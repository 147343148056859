<template>
  <div>
    <v-app-bar flat :clipped-left="$vuetify.breakpoint.lgAndUp" app color="blue darken-3" dark>
      <v-app-bar-nav-icon @click.stop="toggleDrawer()" />
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">{{ $t('instruments') }}</span>
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon @click.stop="dialog.visible = true">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-app-bar>

    <v-container>
      <v-row class="pa-4">
        <v-col cols="12" sm="4">
          <v-card flat>
            <v-skeleton-loader :loading="loadingState" type="list-item-two-line@10">
              <v-list v-if="instruments.length > 0">
                <slot name="item" v-for="item in instruments">
                  <v-list-item @click="edit(item)">
                    <v-list-item-avatar v-if="item.initials">
                      <v-avatar size="32" class="grey lighten-3">{{ item.initials }}</v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="list-item-title">{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                </slot>
              </v-list>
              <v-list v-else>
                <v-list-item disabled>
                  <v-list-item-content>
                    <v-list-item-title class="list-item-title">{{ $t('no-results') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog persistent v-model="dialog.visible" max-width="300">
      <v-form>
        <v-card v-if="!dialog.delete">
          <v-card-title>
            <span class="headline">{{ dialog.form.id ? $t('edit') : $t('add-new') }}</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field :label="$t('name')" required v-model="dialog.form.data.name"></v-text-field>
                <v-text-field
                  :label="$t('initial')"
                  required
                  counter="3"
                  v-model="dialog.form.data.initials"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="red darken-1" text v-if="dialog.form.id" @click="dialog.delete = true">Törlés</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog.form.id ? update() : add()">{{ $t('save') }}</v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-if="dialog.delete">
          <v-card-text class="confirm-text">{{ $t('confirm-to-delete') }}</v-card-text>
          <v-card-actions>
            <v-btn color="red darken-1" text @click="remove()">{{ $t('delete') }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog.delete = false">{{ $t('cancel') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
const initialDialogState = () => ({
  delete: false,
  visible: false,
  loading: false,
  hasError: false,
  form: {
    id: null,
    data: {
      name: null,
      initials: null,
      deleted: false,
    },
  },
});

import * as firebase from 'firebase';
export default {
  name: 'InstrumentsView',
  data: () => ({
    dialog: initialDialogState(),
  }),
  computed: {
    user() {
      return this.$store.getters['user/authUser'];
    },
    userCanEdit() {
      return this.user && ['admin', 'editor'].some((role) => this.user?.userRoles?.includes(role));
    },
    instruments() {
      return this.$store.getters['instrument/instruments'];
    },
    loadingState() {
      return this.$store.getters['loadingState'];
    },
  },
  methods: {
    toggleDrawer() {
      this.$store.dispatch('toggleDrawer', null, { root: true });
    },
    showSnackbar(text) {
      this.$store.dispatch('setSnackbar', { text }, { root: true });
    },
    edit(item) {
      if (this.userCanEdit) {
        const { id, ...data } = item;
        this.dialog.form.id = id;
        this.dialog.form.data = data;
        this.dialog.visible = true;
      }
    },
    add() {
      firebase
        .firestore()
        .collection('instruments')
        .add({ ...this.dialog.form.data, createTime: new Date().toISOString(), updateTime: new Date().toISOString() })
        .then(() => {
          firebase.firestore().collection('stat').doc('collectionCount')
            .update({ instruments: firebase.firestore.FieldValue.increment(1) });

          this.closeDialog();
        })
        .catch((error) => this.showSnackbar(error));
    },
    update() {
      firebase
        .firestore()
        .collection('instruments')
        .doc(this.dialog.form.id)
        .set({ ...this.dialog.form.data, updateTime: new Date().toISOString() })
        .then(() => this.closeDialog())
        .catch((error) => this.showSnackbar(error));
    },
    remove() {
      firebase
        .firestore()
        .collection('instruments')
        .doc(this.dialog.form.id)
        .update({
          deleted: true,
          updateTime: new Date().toISOString(),
        })
        .then(() => this.closeDialog())
        .catch((error) => this.showSnackbar(error));
    },
    closeDialog() {
      this.dialog = Object.assign(this.$data.dialog, initialDialogState());
    },
  },
};
</script>
<style scoped>
.confirm-text {
  font-size: 1rem;
}
</style>
