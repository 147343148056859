import Vue from 'vue';
import Vuex from 'vuex';
import { isBetweenDates, getDatesInRange } from '../helpers/datetime.helper';
import * as moment from 'moment';
import { sortBy } from 'lodash';

import address from './modules/address';
import event from './modules/event';
import eventGroup from './modules/eventGroup';
import eventType from './modules/eventType';
import instrument from './modules/instrument';
import role from './modules/role';
import schoolDistrict from './modules/schoolDistrict';
import user from './modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    init: true,
    drawerVisible: true,
    snackbar: undefined
  },
  mutations: {

    setLoading(state, payload) {
      state.loading = payload;
    },
    setInit(state, payload) {
      state.init = payload;
    },
    toggleDrawer(state) {
      state.drawerVisible = !state.drawerVisible;
    },
    setSnackbar(state, payload) {
      state.snackbar = payload;
    }
  },
  actions: {
    setLoading({ commit }, payload) {
      commit('setLoading', payload, { root: true });
    },
    setInit({ commit }, payload) {
      commit('setInit', payload);
    },
    toggleDrawer({ commit }) {
      commit('toggleDrawer');
    },
    setSnackbar({ commit }, payload) {
      commit('setSnackbar', payload, { root: true });
    }
  },
  getters: {
    init(state) {
      return state.init;
    },
    drawerVisible(state) {
      return state.drawerVisible;
    },
    snackbar(state) {
      return state.snackbar;
    },
    dots(state, getters, rootState, rootGetters) {
      let dots = {};
      const user = rootGetters['user/authUser'];
      const groups = rootGetters['eventGroup/groups'];
      const events = rootGetters['event/events'];
      const selectedUser = rootGetters['event/selectedUser'];
      let eventsAssignedToGroup = [];
      if (user) {
        if (groups && groups.length > 0) {
          groups.forEach((group) => {
            eventsAssignedToGroup = [...eventsAssignedToGroup, ...group.events];

            const participants = group.participants;

            /**
             * Ha kivalasztunk egy adott eloadot a naptar feletti listabol, akkor
             * csak az o turnejait vagy eloadasait jelenitjuk meg a naptarban.
             */
            if (
              selectedUser &&
              !participants.some((participant) => participant.userId === selectedUser)
            ) {
              return;
            }

            const tourUnconfirmed = participants.some((participant) => !participant.confirmed);
            let userIsPerformerOfTheTour = false;
            let selectedUserIsPerformerOfTheTour = false;
            let userConfirmedTheTour = false;
            let selectedUserConfirmedTheTour = false;
            if (user.userRoles.some((role) => ['performer', 'musician'].includes(role))) {
              const index = participants.findIndex((participant) => participant.userId === user.uid);
              userIsPerformerOfTheTour = index !== -1;
              userConfirmedTheTour = userIsPerformerOfTheTour ? group.participants[index].confirmed : false;
            } else if (user.userRoles.every((role) => !['performer', 'musician'].includes(role)) && selectedUser) {
              const index = participants.findIndex((participant) => participant.userId === selectedUser);
              selectedUserIsPerformerOfTheTour = index !== -1;
              selectedUserConfirmedTheTour = selectedUserIsPerformerOfTheTour
                ? group.participants[index].confirmed
                : false;
            }

            const dates = getDatesInRange(
              group.startDate,
              group.endDate ? group.endDate : group.startDate
            );
            dates.forEach((date) => {
              if (user.userRoles.some((role) => ['performer', 'musician'].includes(role)) && !userIsPerformerOfTheTour) {
                return;
              }
              if (!participants || participants.length == 0) {
                dots[date] = 'red';
                return;
              }
              const color = Object.prototype.hasOwnProperty.call(dots, date) ? dots[date] : 'green';
              if (color == 'green') {
                if (user.userRoles.every((role) => !['performer', 'musician'].includes(role)) && selectedUser) {
                  dots[date] = selectedUserConfirmedTheTour ? 'green' : 'red';
                } else if (user.userRoles.every((role) => !['performer', 'musician'].includes(role)) && !selectedUser) {
                  dots[date] = tourUnconfirmed ? 'red' : 'green';
                } else if (userIsPerformerOfTheTour) {
                  dots[date] = userConfirmedTheTour ? 'green' : 'red';
                }
              }
            });
          });
        }
        if (events && events.length > 0) {
          events.forEach((event) => {
            if (!eventsAssignedToGroup.includes(event.id)) {
              const participants = event.participants;
              if (!participants || participants.length == 0) {
                dots[event.date] = 'red';
                return;
              }

              /**
               * Ha kivalasztunk egy adott eloadot a naptar feletti listabol, akkor
               * csak az o turnejait vagy eloadasait jelenitjuk meg a naptarban.
               */
              if (
                selectedUser &&
                !participants.some((participant) => participant.userId === selectedUser)
              ) {
                return;
              }
              const eventUnconfirmed = participants.some((participant) => !participant.confirmed);
              let userIsPerformerOfTheEvent = false;
              let selectedUserIsPerformerOfTheEvent = false;
              let userConfirmedTheEvent = false;
              let selectedUserConfirmedTheEvent = false;
              if (user.userRoles.some((role) => ['performer', 'musician'].includes(role))) {
                const index = participants.findIndex((participant) => participant.userId === user.uid);
                userIsPerformerOfTheEvent = index !== -1;
                userConfirmedTheEvent = userIsPerformerOfTheEvent ? event.participants[index].confirmed : false;
              } else if (user.userRoles.every((role) => !['performer', 'musician'].includes(role)) && selectedUser) {
                const index = participants.findIndex((participant) => participant.userId === selectedUser);
                selectedUserIsPerformerOfTheEvent = index !== -1;
                selectedUserConfirmedTheEvent = selectedUserIsPerformerOfTheEvent
                  ? event.participants[index].confirmed
                  : false;
              }

              if (user.userRoles.some((role) => ['performer', 'musician'].includes(role)) && !userIsPerformerOfTheEvent) {
                return;
              }
              const color = Object.prototype.hasOwnProperty.call(dots, event.date)
                ? dots[event.date]
                : 'green';
              if (color == 'green') {
                if (user.userRoles.every((role) => !['performer', 'musician'].includes(role)) && selectedUser) {
                  dots[event.date] = selectedUserConfirmedTheEvent ? 'green' : 'red';
                } else if (user.userRoles.every((role) => !['performer', 'musician'].includes(role)) && !selectedUser) {
                  dots[event.date] = eventUnconfirmed ? 'red' : 'green';
                } else if (userIsPerformerOfTheEvent) {
                  dots[event.date] = userConfirmedTheEvent ? 'green' : 'red';
                }
              }
            }
          });
        }
      }
      return dots;
    },

    statByMonth: (state, getters, rootState, rootGetters) => (month) => {
      let data = {};
      const loggedInUser = rootGetters['user/authUser'];
      if (loggedInUser && rootGetters['user/filteredUsersBySearchTerm'] && (rootGetters['eventGroup/groups'].length > 0 || rootGetters['event/events'].length > 0)) {
        const date = moment(month, 'YYYY-MM');
        const firstDayOfSelectedMonth = date.startOf('month').format('YYYY-MM-DD');
        const lastDayOfSelectedMonth = date.endOf('month').format('YYYY-MM-DD');

        let countedGroups = [];
        const events = rootGetters['event/events'].filter((event) => {
          return (
            !event.deleted && isBetweenDates(event.date, firstDayOfSelectedMonth, lastDayOfSelectedMonth)
          );
        });

        events.forEach((event) => {
          const group = rootGetters['eventGroup/groups'].find((group) => group.events.includes(event.id));
          const isEventAssignedToGroup = !!group;
          let participants, distance;
          if (isEventAssignedToGroup) {
            participants = group.participants;
            distance = group.distance;
          } else {
            participants = event.participants;
            distance = event.distance;
          }

          participants.forEach((participant) => {
            const id = participant.userId;
            const isDriver = participant.isDriver;
            const user = rootGetters['user/filteredUsersBySearchTerm'].find((user) => user.id === id);
            const firstName = user?.firstname;
            const lastName = user?.lastname;
            const userFullName = (lastName ?? '') + (lastName ? ' ' : '') + (firstName ?? '');
            const confirmed = participant.confirmed;
            // ha a felhasznalo eloado, csak az o adatait dolgozzuk fel
            if (loggedInUser.userRoles.some((role) => ['performer', 'musician'].includes(role)) && loggedInUser.uid !== id) {
              return;
            }
            if (!Object.prototype.hasOwnProperty.call(data, id)) {
              data[id] = {
                userId: id,
                userFullName,
                numConfirmedEvents: 0,
                numUnconfirmedEvents: 0,
                kms: 0,
              };
            }
            data[id].numConfirmedEvents += confirmed ? 1 : 0;
            data[id].numUnconfirmedEvents += confirmed ? 0 : 1;
            if (isDriver && !countedGroups.includes(group.id)) {
              data[id].kms += isNaN(parseInt(distance)) ? 0 : parseInt(distance);
            }
          });

          if (isEventAssignedToGroup && !countedGroups.includes(group.id)) {
            countedGroups.push(group.id);
          }
        });
      }
      return Object.values(data).sort((a, b) => a.userFullName.localeCompare(b.userFullName));
    },

    statByMonthAsCSV: (state, getters, rootState, rootGetters) => (month) => {
      let data = {};
      if (rootGetters['user/filteredUsersBySearchTerm'] && (rootGetters['eventGroup/groups'].length > 0 || rootGetters['event/events'].length > 0)) {
        const date = moment(month, 'YYYY-MM');
        const firstDayOfSelectedMonth = date.startOf('month').format('YYYY-MM-DD');
        const lastDayOfSelectedMonth = date.endOf('month').format('YYYY-MM-DD');

        let countedGroups = [];
        const events = rootGetters['event/events'].filter((event) => {
          return (
            !event.deleted && isBetweenDates(event.date, firstDayOfSelectedMonth, lastDayOfSelectedMonth)
          );
        });

        events.forEach((event) => {
          const group = rootGetters['eventGroup/groups'].find((group) => group.events.includes(event.id));
          const isEventAssignedToGroup = !!group;
          let participants, distance;
          if (isEventAssignedToGroup) {
            participants = group.participants;
            distance = group.distance;
          } else {
            participants = event.participants;
            distance = event.distance;
          }

          participants.forEach((participant) => {
            const id = participant.userId;
            const isDriver = participant.isDriver;
            const user = rootGetters['user/filteredUsersBySearchTerm'].find((user) => user.id === id);
            const firstName = user?.firstname;
            const lastName = user?.lastname;
            const userFullName = (lastName ?? '') + (lastName ? ' ' : '') + (firstName ?? '');
            const confirmed = participant.confirmed;
            if (!Object.prototype.hasOwnProperty.call(data, id)) {
              data[id] = {
                userFullName,
                numConfirmedEvents: 0,
                numUnconfirmedEvents: 0,
                kms: 0,
              };
            }
            data[id].numConfirmedEvents += confirmed ? 1 : 0;
            data[id].numUnconfirmedEvents += confirmed ? 0 : 1;
            if (isDriver && !countedGroups.includes(group.id)) {
              data[id].kms += isNaN(parseInt(distance)) ? 0 : parseInt(distance);
            }
          });

          if (isEventAssignedToGroup && !countedGroups.includes(group.id)) {
            countedGroups.push(group.id);
          }
        });
      }
      const header = [['név', 'előadások (db)', 'előadások - nem elfogadott (db)', 'autóhasználat (km)']];
      const rows = Object.values(sortBy(data, ['userFullName'])).map((item) => {
        return [item.userFullName, item.numConfirmedEvents, item.numUnconfirmedEvents, item.kms];
      });
      return [...header, ...rows];
    },

    eventsByMonthAsCSV: (state, getters, rootState, rootGetters) => (month) => {
      let rows = [];
      if (rootGetters['user/filteredUsersBySearchTerm'] && (rootGetters['eventGroup/groups'].length > 0 || rootGetters['event/events'].length > 0)) {
        const date = moment(month, 'YYYY-MM');
        const firstDayOfSelectedMonth = date.startOf('month').format('YYYY-MM-DD');
        const lastDayOfSelectedMonth = date.endOf('month').format('YYYY-MM-DD');

        const events = rootGetters['event/events'].filter((event) => {
          return (
            !event.deleted && isBetweenDates(event.date, firstDayOfSelectedMonth, lastDayOfSelectedMonth)
          );
        });

        events.forEach((event) => {
          const group = rootGetters['eventGroup/groups'].find((group) => group.events.includes(event.id));
          const isEventAssignedToGroup = !!group;
          let participants, distance;
          if (isEventAssignedToGroup) {
            participants = group.participants;
            distance = group.distance;
          } else {
            participants = event.participants;
            distance = event.distance;
          }
          const address = rootGetters['address/addressById'](event.addressId);

          const p = participants
            .map((participant) => {
              const id = participant.userId;
              const user = rootGetters['user/filteredUsersBySearchTerm'].find((user) => user.id === id);
              return user?.lastname + ' ' + user?.firstname;
            })
            .join(' / ');

          rows.push({
            date: event.date,
            time: event.startTime,
            address: address.name.replace(',', ''),
            participants: p,
            tourName: isEventAssignedToGroup ? group.name : '',
            distance,
          });
        });
      }
      const header = [['dátum', 'kezdés', 'helyszín', 'előadók', 'turné neve', 'úthossz']];
      rows = rows
        .sort(function (a, b) {
          const keyA = a.date + a.time;
          const keyB = b.date + b.time;
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        })
        .map((item) => {
          return [item.date, item.time, item.address, item.participants, item.tourName, item.distance];
        });
      return [...header, ...rows];
    },

    loadingState(state, getters, rootState, rootGetters) {
      return (
        rootGetters['address/addressLoading'] ||
        rootGetters['schoolDistrict/schoolDistrictsLoading'] ||
        rootGetters['instrument/instrumentsLoading'] ||
        rootGetters['roles/rolesLoading'] ||
        rootGetters['user/usersLoading'] ||
        rootGetters['event/eventsLoading'] ||
        rootGetters['eventType/eventTypesLoading'] ||
        rootGetters['eventGroup/eventGroupsLoading']
      );
    },
  },
  modules: {
    address,
    event,
    eventGroup,
    eventType,
    instrument,
    role,
    schoolDistrict,
    user
  }
});
