<template>
  <v-app>
    <v-container class="fill-height" fluid>
      <span class="grey--text lighten-3">v{{ appVersion }}</span>
      <v-row class="fill-height mb-n6" align="center" justify="center" no-gutters>
        <v-col cols="12" sm="8" md="4">
          <!-- <v-card class="mx-auto mb-10" max-width="568" elevation="0">
                        <h1 class="text-center grey--text text--lighten-2">{{ $t('site-title') }}</h1>
                    </v-card> -->
          <v-card class="mx-auto mb-2" max-width="568" :elevation="$vuetify.breakpoint.smAndUp ? 1 : 0">
            <v-card-text class="pa-0" :class="{ 'pa-4': $vuetify.breakpoint.smAndUp }">
              <v-form v-model="form.valid" ref="form">
                <v-text-field
                  :label="$t('email')"
                  name="email"
                  prepend-inner-icon="mdi-email-outline"
                  type="text"
                  v-model="form.email"
                  required
                  :rules="[rules.required, rules.email]"
                  class="mb-6"
                  v-on:keyup.enter="login"
                />
                <v-text-field
                  :label="$t('password')"
                  name="password"
                  prepend-inner-icon="mdi-lock-outline"
                  :append-icon="passwordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="passwordVisible ? 'text' : 'password'"
                  v-model="form.password"
                  required
                  :rules="[rules.required]"
                  v-on:keyup.enter="login"
                  @click:append="passwordVisible = !passwordVisible"
                />
              </v-form>
            </v-card-text>
            <v-card-actions :class="{ 'pa-2 pa-4': $vuetify.breakpoint.smAndUp }">
              <a class="d-none d-sm-block" @click.stop="navigateTo('/elfelejtett-jelszo')">{{
                $t('have-you-forgotten-your-password')
              }}</a>
              <v-spacer />
              <v-btn
                class="d-none d-sm-block"
                outlined
                color="primary"
                v-on:click="login"
                v-bind:loading="loading"
                >{{ $t('login') }}</v-btn
              >
            </v-card-actions>
          </v-card>
          <v-card class="mx-auto mt-4" max-width="568" elevation="0">
            <v-row class="d-block d-sm-none">
              <v-col cols="12" md="6" class="d-flex justify-center">
                <a @click.stop="navigateTo('/elfelejtett-jelszo')">{{ $t('have-you-forgotten-your-password') }}</a>
              </v-col>
            </v-row>
            <v-row class="d-block d-sm-none">
              <v-col cols="12" md="6" class="d-flex justify-center">
                <v-btn
                  depressed
                  color="primary"
                  v-on:click="login"
                  v-bind:loading="loading"
                  width="100%"
                  >{{ $t('login') }}</v-btn
                >
              </v-col>
            </v-row>
            <v-row class="mt-4 mt-md-0" v-if="user && user.loginError">
              <v-col cols="12" class="text-center">
                <span v-if="user.loginError === 'auth/wrong-password'">Hibás jelszó</span>
                <span v-else-if="user.loginError === 'auth/user-not-found'">Az email cím nem található</span>
                <span v-else>Sikertelen bejelentkezés</span>
              </v-col>
            </v-row>
            <!-- <v-row>
                <v-col cols="12" class="d-flex justify-center text-center">
                    Az oldal használatához a Google Chrome böngészőt javasoljuk
                </v-col>
            </v-row> -->
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { rules } from '@/helpers/validator-rules';
import { version } from '@/version';
import { log } from '@/helpers/logger';
export default {
  name: 'LoginView',
  data() {
    return {
      rules,
      appVersion: version,
      form: {
        valid: false,
        email: null,
        password: null,
      },
      loading: false,
      passwordVisible: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters['user/authUser'];
    },
  },
  methods: {
    navigateTo(link) {
      this.$router.push(link);
    },
    async login() {
      if (this.form.valid) {
        this.loading = true;
        try {
          const response = await this.$store.dispatch('user/signin', { email: this.form.email, password: this.form.password });
          log({ type: 'login', userId: response.user.uid, email: response.user.email });
          this.navigateTo('/');
        } catch (error) {
          log({ type: 'login-error', userId: null, email: this.form.email, errorCode: error.code, errorMessage: error.message });
        } finally {
          this.loading = false;
        }
      } else {
        this.$refs.form.validate();
      }
    },
  },
  destroyed() {
    if (this.user && !this.user.uid) {
      this.$store.dispatch('user/resetUser', null, { root: true });
    }
  },
};
</script>
<style>
.v-application--is-ltr .v-text-field .v-input__prepend-inner {
  padding-right: 16px;
}
</style>
