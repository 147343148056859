<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="pl-0 pr-4 py-2">
      <v-list-item class="list-item"
        ><v-list-item-content class="my-0 py-0">
          <v-list-item-title class="valign-center">{{ group.name }}</v-list-item-title>
          <v-list-item-subtitle class="items-separated-by-dot">
            <span>{{
              formatDate(group.startDate, 'MM.DD') +
              (group.endDate && group.startDate !== group.endDate
                ? ' - ' + formatDate(group.endDate, 'MM.DD')
                : '')
            }}</span>
            <span>{{ events.length }} {{ $tc('event', events.length) | lowercase }}</span>
            <span v-if="group.distance && group.distance > 0">{{ group.distance + ' km' }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <!-- eloadok -->
      <div v-if="group.participants.length > 0" class="mt-8 mb-4 block-title amber lighten-4">
        {{ $t('participants') }}
      </div>
      <v-card v-if="group.participants.length > 0" elevation="1">
        <v-list>
          <template v-for="(participant, index) in group.participants">
            <v-list-item v-bind:key="participant.userId">
              <v-list-item-content>
                <v-list-item-title class="ws-pl">
                  {{ userById(participant.userId)?.lastname }} {{ userById(participant.userId)?.firstname }}
                </v-list-item-title>
                <v-list-item-subtitle class="ws-pl items-separated-by-dot">
                  <span class="ws-pl" v-if="participant.instrumentId">{{ instrumentById(participant.instrumentId)?.name }}</span>
                  <span class="ws-pl" v-else>{{ userRoleById(participant.roleId)?.name }}</span>
                  <span class="ws-pl">{{ userById(participant.userId)?.telephone }}</span>
                  <span class="ws-pl">
                    <a :href="getMailtoParticipantsLink(participant)">{{ userById(participant.userId)?.email }}</a>
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="list-item-action">
                <v-icon v-if="participant.isDriver" class="ml-4">mdi-car-side</v-icon>
                <v-icon v-if="participant.confirmed" color="green lighten-1" class="ml-4">mdi-check-circle</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-divider
              v-if="index < group.participants.length - 1 && group.participants.length > 0"
              v-bind:key="index"
            />
          </template>
        </v-list>
      </v-card>
      <div class="mt-4" v-if="group.participants?.length">
        <a :href="getMailtoParticipantsLink()">Email mindenkinek</a>
      </div>

      <!-- eloadasok -->
      <div v-if="events.length > 0" class="mt-10 mb-4 block-title amber lighten-4">{{ $t('events') }}</div>
      <v-expansion-panels :inset="events.length > 1" v-if="events.length > 0">
        <v-event-list-item
          v-for="(event, index) in events"
          v-bind:key="index"
          v-bind:event="event"
          v-bind:inGroup="true"
          v-on:copyEvent="copyEvent(event.id)"
          v-on:editEvent="editEvent(event.id)"
          v-on:removeEventFromGroup="removeEventFromGroup(event.id)"
        />
      </v-expansion-panels>

      <!-- hotelek -->
      <div
        v-if="group.accomodation.length > 0 || group.accomodationNote"
        class="mt-10 mb-4 block-title amber lighten-4"
      >
        {{ $t('accomodation') }}
      </div>
      <v-expansion-panels :inset="group.accomodation.length > 1" v-if="group.accomodation.length > 0">
        <v-address-list-item
          v-for="(id, index) in group.accomodation"
          v-bind:key="index"
          v-bind:id="id"
        />
      </v-expansion-panels>

      <!-- szallas megjegyzes -->
      <div
        class="pre-line"
        :class="group.accomodation.length > 0 ? 'mt-4' : ''"
        v-if="group.accomodationNote"
      >
        {{ group.accomodationNote }}
      </div>

      <!-- megjegyzes -->
      <div v-if="group.note" class="mt-10 mb-3 block-title amber lighten-4">{{ $t('note') }}</div>
      <div class="pre-line" v-if="group.note">{{ group.note }}</div>
      <v-divider class="mt-10 mb-4" v-if="isActionButtonGroup1Visible || isActionButtonGroup2Visible"></v-divider>

      <!-- gombok -->
      <v-row v-if="isActionButtonGroup1Visible">
        <v-spacer></v-spacer>
        <v-col cols="auto" class="pa-0" v-if="!groupConfirmed">
          <v-btn color="blue darken-1" text @click="confirmGroup()">{{ $t('accept') }}</v-btn>
        </v-col>
        <v-col cols="auto" class="py-0 green--text" v-if="groupConfirmed"
          >{{ $t('accepted') }}
          <!-- <v-btn depressed color="ml-4" @click="unconfirmGroup()">Visszavonás</v-btn> -->
        </v-col>
      </v-row>

      <v-row v-if="isActionButtonGroup2Visible">
        <v-col cols="auto" class="pa-0">
          <v-btn color="red darken-1" text @click="deleteGroup()">{{ $t('delete-tour') }}</v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto" class="pa-0">
          <v-btn color="blue darken-1" text @click="editGroup()">{{ $t('edit-tour') }}</v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import * as firebase from 'firebase';
import i18n from '@/i18n';
import { formatDate } from '@/helpers/datetime.helper';
import EventListItem from '@/components/list/EventListItem.vue';
import AddressListItem from '@/components/list/AddressListItem.vue';

export default {
  name: 'GroupListItem',
  props: ['group', 'date'],
  components: { 'v-event-list-item': EventListItem, 'v-address-list-item': AddressListItem },
  computed: {
    user() {
      return this.$store.getters['user/authUser'];
    },
    userCanEdit() {
      return this.user && ['admin', 'editor'].some((role) => this.user?.userRoles?.includes(role));
    },
    users() {
      return this.$store.getters['user/usersWithParticipantRoles'];
    },
    userRoles() {
      return this.$store.getters['user/userRoles'];
    },
    instruments() {
      return this.$store.getters['instrument/instruments'];
    },
    address() {
      return (
        this.group && this.group.accomodation && this.$store.getters['address/addressById'](this.group.accomodation)?.data
      );
    },
    events() {
      if (!this.group || !this.date) return [];

      const groupEvents = [...this.group.events];
      return groupEvents
        .filter((id) => !!this.$store.getters['event/eventById'](id))
        .map((id) => {
          const event = this.$store.getters['event/eventById'](id);
          return { id, ...event };
        })
        .filter((event) => event.date == this.date)
        .sort((a, b) => {
          const dt1 = new Date(a.date + ' ' + a.startTime).getTime();
          const dt2 = new Date(b.date + ' ' + b.startTime).getTime();
          return dt1 > dt2 ? 1 : -1;
        });
    },
    groupConfirmed() {
      if (this.group && this.user) {
        const userId = this.user.uid;
        const participants = this.group.participants;
        if (participants.length == 0) {
          return false;
        }
        const participant = participants.find((participant) => participant.userId == userId);
        if (!participant) {
          return false;
        }
        return participant.confirmed;
      } else {
        return false;
      }
    },
    isActionButtonGroup1Visible() {
      return this.user?.userRoles.some((role) => ['performer', 'musician'].includes(role));
    },
    isActionButtonGroup2Visible() {
      return this.user?.userRoles.some((role) => ['admin', 'editor'].includes(role));
    },
  },
  methods: {
    userById(id) {
      return this.users?.find((user) => user.id === id);
      // if (this.users && this.users.length > 0 && id) {
      //   const user = this.users?.find((user) => user.id === id);
      //   if (user) {
      //     return user;
      //   } else {
      //     return { lastname: '', firstname: '' };
      //   }
      // } else {
      //   return { lastname: '', firstname: '' };
      // }
    },
    userRoleById(id) {
      return this.userRoles?.find((role) => role.id === id) ?? '';
    },
    instrumentById(id) {
      return this.instruments?.find((instrument) => instrument.id === id) ?? '';
    },
    editGroup() {
      this.$emit('editGroup');
    },
    deleteGroup() {
      this.$emit('deleteGroup');
    },
    confirmGroup() {
      if (this.group && this.user) {
        const { id, ...data } = JSON.parse(JSON.stringify(this.group));
        const userId = this.user.uid;
        data.participants.forEach((participant) => {
          if (participant.userId == userId) {
            participant.confirmed = true;
          }
        });
        firebase
          .firestore()
          .collection('eventGroups')
          .doc(id)
          .set({ ...data, updateTime: new Date().toISOString() })
          .catch(() => {
            this.$store.dispatch('setSnackbar', { text: i18n.t('command-unsuccessful-try-again') }, { root: true });
          });
      }
    },
    // unconfirmGroup() {
    //     if (this.group && this.user) {
    //         const updatedGroup = JSON.parse(
    //             JSON.stringify(this.group)
    //         );
    //         const userId = this.user.uid;
    //         const participants = updatedGroup.participants;
    //         if (participants.length == 0) {
    //             return false;
    //         }
    //         participants.map(participant => {
    //             if (participant.userId == userId) {
    //                 participant.confirmed = false;
    //             }
    //         });
    //         firebase
    //             .firestore()
    //             .collection("eventGroups")
    //             .doc(this.group.id)
    //             .set(updatedGroup)
    //             .then(() => {
    //
    // console.log('ok')
    //             });
    //
    // .catch(error => {
    //
    // console.log(error)
    //
    // })
    //     }
    // },
    copyEvent(id) {
      this.$emit('copyEvent', id);
    },
    editEvent(id) {
      this.$emit('editEvent', id);
    },
    removeEventFromGroup(id) {
      this.$emit('removeEventFromGroup', id);
    },
    formatDate(date, format) {
      return formatDate(date, format);
    },
    getMailtoParticipantsLink(participant) {
      const recipients = [];
      this.group.participants?.forEach((p) => {
        // if participant is specified, only add that participant to the recipients list
        if (participant && participant.userId !== p.userId) return;

        const user = this.userById(p.userId);
        recipients.push(user?.lastname + ' ' + user?.firstname + ' <' + user?.email + '>');
      });

      let subject = '?subject=';
      if (this.group.startDate) subject += this.group.startDate.replace(/-/g, ".");
      if (this.group.endDate) subject += '-' + this.group.endDate.replace(/-/g, ".");
      if (this.group.name) subject += ' ' + this.group.name;

      return 'mailto:' + recipients.join(',') + subject;
    },
  },
  filters: {
    lowercase: function (value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toLowerCase() + value.slice(1);
    },
  },
};
</script>
<style scoped>
.list-item-action {
  flex-direction: row;
  align-items: center;
}
.valign-center {
  display: flex;
  align-items: center;
}
.items-separated-by-dot span:after {
  content: '•';
  color: #ababab;
  margin-left: 10px;
  margin-right: 10px;
}
.items-separated-by-dot span {
  display: inline-block;
}
.items-separated-by-dot span:last-child:after {
  content: '';
}
.ws-pl {
  white-space: pre-line;
}
.pre-line {
  white-space: pre-line;
}
.block-title {
  padding: 3px 10px;
  display: inline-block;
}
</style>
