import * as moment from 'moment'

moment.locale('hu');

export const days = ['Vasárnap', 'Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat']
export function getDayName(dateString) {
    const d = new Date(dateString)
    return days[d.getDay()]
}
export function addTime(startTime, durationInMinutes) {
    return moment(startTime, 'HH:mm').add(durationInMinutes, 'minutes');
}
export function isBetweenDates(date, startDate, endDate) {
    return moment(date).isBetween(startDate, endDate, null, [])
}
export function formatDate(date, format = 'YYYY.MM.DD') {
    if (!date) return null
    return moment(date, 'YYYY-MM-DD').format(format) // https://momentjs.com/docs/#/displaying/format/
}
export function getDatesInRange(startDate, endDate) {
    let dateArray = [];
    let currentDate = moment(startDate);
    const stopDate = moment(endDate);
    while (currentDate <= stopDate) {
        dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
        currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
}
export function addDay(date, days) {
    return moment(date).add(days, 'days').format('YYYY-MM-DD')
}
export function subtractDay(date, days) {
    return moment(date).subtract(days, 'days').format('YYYY-MM-DD')
}
export function addMonth(date, months) {
    return moment(date, 'YYYY-MM').add(months, 'months').format('YYYY-MM')
}
export function subtractMonth(date, months) {
    return moment(date, 'YYYY-MM').subtract(months, 'months').format('YYYY-MM')
}
export function isValidTime(time) {
    return moment(time, 'HH:mm', true).isValid()
}