<template>
  <v-dialog fullscreen hide-overlay transition="dialog-bottom-transition" :value="visible">
    <v-card>
      <v-toolbar color="blue darken-3" dark>
        <v-btn icon @click="close()"> <v-icon>mdi-close</v-icon> </v-btn>
        <v-toolbar-title v-if="copying">{{ $t('copy-event') }}</v-toolbar-title>
        <v-toolbar-title v-else-if="form.id">{{ $t('edit') }}</v-toolbar-title>
        <v-toolbar-title v-else>{{ $t('new-event') }}</v-toolbar-title> <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn text :loading="loading" @click="save()">{{ $t('save') }}</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-form v-model="formValid" ref="form">
        <v-container fluid class="pa-4">
          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <v-row>
                <!-- date -->
                <v-col cols="12" md="2">
                  <v-text-field :value="form.data.date" :label="$t('date')" persistent-hint disabled></v-text-field>
                </v-col>

                <!-- time -->
                <v-col cols="12" md="2">
                  <v-text-field
                    v-mask="mask.time"
                    :label="$t('start-time')"
                    required
                    :rules="[rules.required]"
                    v-model="form.data.startTime"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    v-mask="mask.time"
                    :label="$t('end-time')"
                    required
                    :rules="[rules.required]"
                    v-model="form.data.endTime"
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12" md="2"><v-text-field :label="$t('distance')" hint="km" v-model="form.data.distance"></v-text-field></v-col> -->
              </v-row>

              <!-- event type -->
              <v-row>
                <v-col cols="12">
                  <v-radio-group v-model="form.data.eventType" row required :rules="[rules.required]" :label="$t('event-type')">
                    <v-radio :label="t.shortName" :value="t.id" v-for="t in eventTypes" :key="t.id" />
                  </v-radio-group>
                </v-col>
              </v-row>

              <!-- address -->
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="form.data.addressId"
                    :items="addresses"
                    :label="$t('place')"
                    item-text="name"
                    item-value="id"
                    auto-select-first
                    clearable
                    required
                    :rules="[rules.required]"
                  >
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title v-text="data.item.name" />
                          <v-list-item-subtitle>
                            {{ data.item.zip ? data.item.zip + ' ' : '' }}
                            {{ data.item.city ? data.item.city + ' ' : '' }}
                            {{ (data.item.zip || data.item.city) ? ', ' : '' }}
                            {{ data.item.address }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

              <!-- note -->
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    dense
                    auto-grow
                    rows="1"
                    row-height="24"
                    :label="$t('note')"
                    v-model="form.data.note"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
            <v-spacer></v-spacer>

            <!-- participants -->
            <v-col cols="12" md="5">
              <v-participant-list :participants.sync="form.data.participants"></v-participant-list>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import * as firebase from 'firebase';
import { userById } from '@/helpers/form.helper';
import { addTime, isValidTime } from '@/helpers/datetime.helper';
import { rules } from '../../helpers/validator-rules';
import { mask } from 'vue-the-mask';
import ParticipantList from '../list/ParticipantList.vue';

export default {
  name: 'EventDialog',
  props: ['visible', 'id', 'copying', 'date'],
  directives: { mask },
  components: { 'v-participant-list': ParticipantList },
  data() {
    return {
      loading: false,
      searchTerm: '',
      form: {
        id: null,
        data: {
          deleted: false,
          date: null,
          startTime: null,
          endTime: null,
          addressId: null,
          participants: [],
          distance: null,
          note: null,
          eventType: null,
        },
      },
      formValid: false,
      addPerformerDialog: false,
      removeParticipantDialog: false,
      participantId: null,
      mask: {
        date: '####-##-##',
        time: '##:##',
      },
      rules,
    };
  },
  computed: {
    event() {
      return this.id && this.$store.getters['event/eventById'](this.id);
    },
    eventTypes() {
      return this.$store.getters['eventType/eventTypes'];
    },
    addresses() {
      return this.$store.getters['address/addresses'];
    },
    users() {
      return this.$store.getters['user/filteredUsersBySearchTerm'];
    },
    instruments() {
      return this.$store.getters['instrument/instruments'];
    },
  },
  watch: {
    'form.data.startTime': function (time) {
      if (isValidTime(time) && !this.form.data.endTime) {
        this.form.data.endTime = addTime(this.form.data.startTime, 45).format('HH:mm');
      }
    },
  },
  methods: {
    save() {
      if (this.formValid) {
        this.loading = true;
        // if (this.form.data.participants.length == 5) {
        //   this.form.data.clean = true;
        // } else {
        //   this.form.data.clean = false;
        // }
        if (this.copying) {
          firebase
            .firestore()
            .collection('events')
            .add({ ...this.form.data, createTime: new Date().toISOString(), updateTime: new Date().toISOString() })
            .then(() => {
              firebase.firestore().collection('stat').doc('collectionCount')
                .update({ events: firebase.firestore.FieldValue.increment(1) });

              this.loading = false;
              this.close();
            })
            .catch((error) => {
              this.loading = false;
              this.showSnackbar(error);
            });
        } else if (this.form.id) {
          firebase
            .firestore()
            .collection('events')
            .doc(this.form.id)
            .set({ ...this.form.data, updateTime: new Date().toISOString() })
            .then(() => {
              this.loading = false;
              this.close();
            })
            .catch((error) => {
              this.loading = false;
              this.showSnackbar(error);
            });
        } else {
          firebase
            .firestore()
            .collection('events')
            .add({ ...this.form.data, createTime: new Date().toISOString(), updateTime: new Date().toISOString() })
            .then(() => {
              firebase.firestore().collection('stat').doc('collectionCount')
                .update({ events: firebase.firestore.FieldValue.increment(1) });

              this.loading = false;
              this.close();
            })
            .catch((error) => {
              this.loading = false;
              this.showSnackbar(error);
            });
        }
      } else {
        this.$refs.form.validate();
      }
    },
    close() {
      this.$emit('close');
    },
    userById(id) {
      return userById(this.users, id);
    },
    addPerformer(form) {
      this.form.data.participants.push({ ...form, confirmed: false });
      this.addPerformerDialog = false;
    },
    showRemoveParticipantDialog(id) {
      this.userId = id;
      this.removeParticipantDialog = true;
    },
    removeParticipant() {
      const index = this.form.data.participants.findIndex((p) => p.userId === this.participantId);
      this.form.data.participants.splice(index, 1);
      this.removeParticipantDialog = false;
    },
    showSnackbar(text) {
      this.$store.dispatch('setSnackbar', { text }, { root: true });
    },
    addTime(time, durationInMinutes) {
      return addTime(time, durationInMinutes);
    },
    isValidTime(time) {
      return isValidTime(time);
    },
  },
  created() {
    if (this.id) {
      const { id, ...data } = this.event;
      this.form.id = this.id;
      this.form.data = JSON.parse(JSON.stringify(data));
      // this.form.data.eventType = String(this.form.data.eventType);
    }
    if (this.date) {
      this.form.data.date = this.date;
    }
  },
};
</script>
<style scoped>
.confirm-text {
  font-size: 1rem;
}
</style>
