<template>
  <v-expansion-panel v-if="event">
    <v-expansion-panel-header class="pl-0 pr-4 py-2">
      <v-list-item class="list-item">
        <v-list-item-content>
          <v-list-item-title class="valign-center mb-2">
            <v-avatar left class="grey lighten-3 mr-2" size="24" v-if="event.eventType">{{
              eventTypeById(event.eventType).shortName
            }}</v-avatar>
            <span>{{ event.startTime }}</span> <span v-if="event.endTime">-</span>
            <span>{{ event.endTime }}</span>
          </v-list-item-title>
          <v-list-item-subtitle v-if="address">{{ address.name }}</v-list-item-subtitle>
          <v-list-item-subtitle v-if="address">
            {{ address.zip ? address.zip + ' ' : '' }}
            {{ address.city ? address.city + ' ' : '' }}
            {{ (address.zip || address.city) ? ', ' : '' }}
            {{ address.address }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="body-2">
      <v-list dense class="pt-0">
        <v-list-item class="list-item pa-0" v-if="address.city && address.address">
          <v-list-item-icon class="py-1 my-0 mr-4"> <v-icon small>mdi-map-marker-outline</v-icon> </v-list-item-icon>
          <v-list-item-content class="pa-0">
            <a :href="getGoogleMapsDirUrl(address)" target="_blank">{{ $t('display-on-map') }}</a>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="list-item pa-0" v-if="address && address.contactName">
          <v-list-item-icon class="py-1 my-0 mr-4"> <v-icon small>mdi-account-box-outline</v-icon> </v-list-item-icon>
          <v-list-item-content class="pa-0">{{ address.contactName }}</v-list-item-content>
        </v-list-item>
        <v-list-item class="list-item pa-0" v-if="address && address.contactTelephone">
          <v-list-item-icon class="py-1 my-0 mr-4"> <v-icon small>mdi-phone-outline</v-icon> </v-list-item-icon>
          <v-list-item-content class="pa-0">
            <a :href="'tel:' + address.contactTelephone">{{ address.contactTelephone }}</a>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="list-item pa-0" v-if="address && address.contactEmail">
          <v-list-item-icon class="py-1 my-0 mr-4"> <v-icon small>mdi-email-outline</v-icon> </v-list-item-icon>
          <v-list-item-content class="pa-0">
            <a :href="'mailto:' + address.contactEmail">{{ address.contactEmail }}</a>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="list-item pa-0" v-if="(address && address.note) || event.note">
          <v-list-item-icon class="py-1 my-0 mr-4"> <v-icon small>mdi-note-outline</v-icon> </v-list-item-icon>
          <v-list-item-content class="py-1">
            <span v-if="address && address.note">{{ address.note }}<br /></span>
            <span v-if="event.note">{{ event.note }}</span>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!-- eloadok -->
      <div v-if="event.participants.length > 0" :class="event.participants.length > 0 ? 'mt-6 mb-2' : 'mb-2'">
        {{ $t('participants') }}
      </div>
      <v-card
        v-if="event.participants.length > 0"
        elevation="1"
        :class="event.participants.length > 0 ? 'mb-6' : ''"
      >
        <v-list>
          <template v-for="(participant, index) in event.participants">
            <v-list-item v-bind:key="participant.userId">
              <v-list-item-content>
                <v-list-item-title class="ws-pl">
                  {{ userById(participant.userId)?.lastname }} {{ userById(participant.userId)?.firstname }}
                </v-list-item-title>
                <v-list-item-subtitle class="ws-pl items-separated-by-dot">
                  <span class="ws-pl" v-if="participant.instrumentId">{{ instrumentById(participant.instrumentId)?.name }}</span>
                  <span class="ws-pl" v-else>{{ userRoleById(participant.roleId)?.name }}</span>
                  <span class="ws-pl">{{ userById(participant.userId)?.telephone }}</span>
                  <!-- <span class="ws-pl">{{ userById(participant.userId)?.email }}</span> -->
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="list-item-action">
                <v-icon v-if="participant.isDriver" class="ml-4">mdi-car-side</v-icon>
                <v-icon v-if="participant.confirmed" color="green lighten-1" class="ml-4">mdi-check-circle</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-divider
              v-if="index < event.participants.length - 1 && event.participants.length > 0"
              v-bind:key="index"
            ></v-divider>
          </template>
        </v-list>
      </v-card>
      <v-divider class="mt-4 my-4" v-if="isActionButtonGroup1Visible || isActionButtonGroup2Visible"></v-divider>

      <!-- gombok -->
      <v-row v-if="isActionButtonGroup1Visible">
        <v-spacer></v-spacer>
        <v-col cols="auto" class="pa-0" v-if="!eventConfirmed">
          <v-btn color="blue darken-1" text @click="confirmEvent()">{{ $t('accept') }}</v-btn>
        </v-col>
        <v-col cols="auto" class="py-0 green--text" v-if="eventConfirmed">{{ $t('accepted') }}</v-col>
      </v-row>

      <v-row v-if="isActionButtonGroup2Visible">
        <v-col cols="auto" class="pa-0">
          <v-btn color="red darken-1" text @click="inGroup ? removeEventFromGroup() : deleteEvent()">{{
            inGroup ? $t('remove') : $t('delete')
          }}</v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto" class="pa-0">
          <v-btn color="blue darken-1" text @click="copyEvent()">{{ $t('copy-event') }}</v-btn>
        </v-col>
        <v-col cols="auto" class="pa-0">
          <v-btn color="blue darken-1" text @click="editEvent()">{{ $t('edit') }}</v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import * as firebase from 'firebase';
import i18n from '@/i18n';
import { mdiAccountBoxOutline, mdiEmailOutline, mdiMapMarkerOutline, mdiNoteOutline, mdiPhoneOutline } from '@mdi/js';
import { getGoogleMapsDirUrl } from '../../helpers/googleMap';
export default {
  name: 'EventListItem',
  props: ['event', 'inGroup'],
  data() {
    return {
      icon: {
        contact: mdiAccountBoxOutline,
        email: mdiEmailOutline,
        mapMarker: mdiMapMarkerOutline,
        note: mdiNoteOutline,
        telephone: mdiPhoneOutline,
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters['user/authUser'];
    },
    userCanEdit() {
      return this.user && ['admin', 'editor'].some((role) => user?.userRoles?.includes(role));
    },
    users() {
      return this.$store.getters['user/filteredUsersBySearchTerm'];
    },
    userRoles() {
      return this.$store.getters['user/userRoles'];
    },
    instruments() {
      return this.$store.getters['instrument/instruments'];
    },
    address() {
      return this.event && this.event.addressId && this.$store.getters['address/addressById'](this.event.addressId);
    },
    eventConfirmed() {
      if (this.event && this.user) {
        const userId = this.user.uid;
        const participants = this.event.participants;
        if (participants.length == 0) {
          return false;
        }
        const participant = participants.find((participant) => participant.userId == userId);
        if (!participant) {
          return false;
        }
        return participant.confirmed;
      } else {
        return false;
      }
    },
    isActionButtonGroup1Visible() {
      return this.user?.userRoles.some((role) => ['performer', 'musician'].includes(role));
    },
    isActionButtonGroup2Visible() {
      return this.user?.userRoles.some((role) => ['admin', 'editor'].includes(role));
    },
    eventTypes() {
      return this.$store.getters['eventType/eventTypesInclDeleted'];
    }
  },
  methods: {
    userById(id) {
      return this.users?.find((user) => user.id === id)?.data ?? '';
    },
    userRoleById(id) {
      return this.userRoles?.find((role) => role.id === id)?.data ?? '';
    },
    instrumentById(id) {
      return this.instruments?.find((instrument) => instrument.id === id) ?? '';
    },
    eventTypeById(id) {
      return this.eventTypes?.find((et) => et.id === id) ?? '';
    },
    copyEvent() {
      this.$emit('copyEvent');
    },
    editEvent() {
      this.$emit('editEvent');
    },
    removeEventFromGroup() {
      this.$emit('removeEventFromGroup');
    },
    deleteEvent() {
      this.$emit('deleteEvent');
    },
    confirmEvent() {
      if (this.event && this.user) {
        const { id, ...data } = JSON.parse(JSON.stringify(this.event));
        const userId = this.user.uid;
        data.participants.forEach((participant) => {
          if (participant.userId == userId) {
            participant.confirmed = true;
          }
        });
        firebase
          .firestore()
          .collection('events')
          .doc(id)
          .set({ ...data, updateTime: new Date().toISOString() })
          .catch(() => {
            this.$store.dispatch('setSnackbar', { text: i18n.t('command-unsuccessful-try-again') }, { root: true });
          });
      }
    },
    getGoogleMapsDirUrl(address) {
      return getGoogleMapsDirUrl(address);
    }
  },
};
</script>
<style scoped>
.list-item {
  min-height: unset;
}
.list-item-action {
  flex-direction: row;
  align-items: center;
}
.ws-pl {
  white-space: pre-line;
}
.items-separated-by-dot span:after {
  content: '•';
  color: #ababab;
  margin-left: 10px;
  margin-right: 10px;
}
.items-separated-by-dot span {
  display: inline-block;
}
.items-separated-by-dot span:last-child:after {
  content: '';
}
</style>
