<template>
  <v-dialog persistent :value="visible" max-width="400">
    <v-form>
      <v-card>
        <v-card-text class="confirm-text">{{ text }}</v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="confirm()">{{ actionText }}</v-btn> <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close()">{{ $t('cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
export default {
  name: 'ConfirmDialog',
  props: ['visible', 'text', 'actionText'],
  methods: {
    confirm() {
      this.$emit('confirmed');
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
<style scoped>
.confirm-text {
  font-size: 1rem;
}
</style>
