import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import hu from 'vuetify/es5/locale/hu';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { hu },
        current: 'hu',
    },
    icons: {
        iconfont: 'mdi',
    },
});
