export function userById(users, id) {
  if (users && users.length > 0 && id) {
    const user = users.find(user => user.id === id)
    if (user) {
      return user.data
    } else {
      return null
    }
  } else {
    return null;
  }
}
export function roleById(roles, id) {
  if (roles && roles.length > 0 && id) {
    const role = roles.find(role => role.id === id)
    if (role) {
      return role
    } else {
      return null
    }
  } else {
    return null;
  }
}
export function addressById(addresses, id) {
  if (addresses && addresses.length > 0 && id) {
    const address = addresses.find(address => address.id === id)
    if (address) {
      return address.data
    } else {
      return null
    }
  } else {
    return null;
  }
}

export const getArrayItemById = (array, id) => array?.find((item) => item.id === id);
