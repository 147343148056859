import * as firebase from 'firebase';
import { readAllFromIndexedDB, writeToIndexedDB } from '@/services/indexedDB';
import { listener } from '@/services/firebase';

let unsubscribe;

export default {
  namespaced: true,
  state: {
    events: [],
    eventsLoading: false,
    selectedUser: null,
  },
  actions: {
    async fetchEvents({ commit }) {
      commit('setEventsLoading', true);

      const { data: initialData, lastUpdateTime: initialLastUpdateTime } = await readAllFromIndexedDB('events');
      console.log('evt/lut', initialLastUpdateTime);
      commit('addEvents', initialData);

      const collectionRef = firebase.firestore().collection('events');
      const query = collectionRef.orderBy('updateTime');

      const callback = async (snapshot) => {
        console.log('snap/ev', snapshot.docs.length);
        if (snapshot.docs.length === 0) {
          commit('setEventsLoading', false);
          return;
        };

        snapshot.docs.forEach(async (doc) => {
          await writeToIndexedDB('events', { id: doc.id, ...doc.data() });
        });
        const { data, lastUpdateTime } = await readAllFromIndexedDB('events');
        commit('addEvents', data);
        commit('setEventsLoading', false);
        unsubscribe();
        unsubscribe = listener(query.where('updateTime', '>', lastUpdateTime), callback);
      }

      unsubscribe = listener(initialLastUpdateTime ? query.where('updateTime', '>', initialLastUpdateTime) : query, callback);
    },
    setEventFilterTerm({ commit }, payload) {
      commit('setEventFilterTerm', payload);
    },
    unsubscribe({ commit }) {
      unsubscribe();
    }
  },
  mutations: {
    addEvents(state, payload) {
      state.events = payload;
    },
    setEventsLoading(state, payload) {
      state.eventsLoading = payload;
    },
    setEventFilterTerm(state, payload) {
      if (payload) {
        if (payload === '0') {
          state.selectedUser = null;
        } else {
          state.selectedUser = payload;
        }
      }
    },
  },
  getters: {
    events(state) {
      return state.events?.filter((item) => !item.deleted) ?? [];
    },
    eventsByDate: (state, getters) => (date) => {
      if (!date) return [];

      return getters.events.filter((event) => event.date == date);
    },
    eventById: (state) => (id) => {
      return state.events.find((item) => item.id === id);
    },
    ungroupedEventsByDate: (state, getters, rootState, rootGetters) => (date) => {
      const events = state.events;
      if (date && events?.length > 0) {
        return events
          .filter((event) => event.date == date)
          .filter((event) => {
            // console.log('event', event);
            const authUserId = rootGetters['user/authUser']?.uid;
            const participants = event.participants;
            if (rootGetters['user/authUser']?.userRoles.some((role) => ['musician', 'performer'].includes(role))) {
              if (participants.length == 0) {
                return false;
              }
              return participants.some((participant) => participant.userId == authUserId);
            } else if (state.selectedUser) {
              return participants.some((participant) => participant.userId == state.selectedUser);
            } else {
              return true;
            }
          })
          .filter((event) => {
            const eventGroups = rootGetters['eventGroup/groupsByDate'](date).map((group) => {
              const events = group.events
                .filter((id) => !!rootGetters['event/eventById'](id))
                .map((id) => {
                  const event = rootGetters['event/eventById'](id) ?? null;
                  return { id, ...event };
                })
                .filter((event) => event.date == date)
                .sort((a, b) => {
                  const dt1 = new Date(a.date + ' ' + a.startTime).getTime();
                  const dt2 = new Date(b.date + ' ' + b.startTime).getTime();
                  return dt1 > dt2 ? 1 : -1;
                });
              // .map(event => event.id)
              return { ...group, events };
            });
            if (eventGroups.length > 0) {
              let eventIds = [];
              eventGroups.forEach((group) => (eventIds = [...eventIds, ...group.events.map((event) => event.id)]));
              return !eventIds.includes(event.id);
            } else {
              return true;
            }
          })
          .sort((a, b) => {
            const dt1 = new Date(a.date + ' ' + a.startTime).getTime();
            const dt2 = new Date(b.date + ' ' + b.startTime).getTime();
            return dt1 > dt2 ? 1 : -1;
          });
      } else {
        return [];
      }
    },
    selectedUser(state) {
      return state.selectedUser;
    }
  }
}
