<template>
  <v-app>
    <v-app-bar color="transparent" dense flat>
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span>{{ $t('forgotten-password') }}</span>
      </v-toolbar-title>
      <v-spacer />
    </v-app-bar>

    <v-container class="fill-height" fluid>
      <v-row class="fill-height mb-n6" align="center" justify="center" no-gutters>
        <v-col cols="12" sm="8" md="4">
          <v-card
            class="mx-auto mb-2"
            max-width="414"
            :elevation="$vuetify.breakpoint.smAndUp ? 1 : 0"
            v-if="!resetRequested"
          >
            <v-card-text class="pa-0" :class="{ 'pa-4': $vuetify.breakpoint.smAndUp }">
              <v-form v-model="form.valid" ref="form">
                <v-text-field
                  :label="$t('email-address')"
                  name="email"
                  type="text"
                  v-model="form.email"
                  required
                  :rules="[rules.required, rules.email]"
                  validate-on-blur
                  class="mb-6"
                  v-on:keyup.enter="login"
                />
              </v-form>
            </v-card-text>
            <v-card-actions class="px-4">
              <v-spacer />
              <v-btn depressed v-if="!resetRequested" v-on:click="reset" v-bind:loading="loading">{{
                $t('next')
              }}</v-btn>
            </v-card-actions>
          </v-card>
          <v-card class="text-center mx-auto mt-4" max-width="414" elevation="0" v-if="resetRequested">
            <span>{{ $t('reset-requested') }}</span>
            <br /><br />
            <v-btn outlined color="primary" @click="navigateTo('/login')">{{ $t('login') }}</v-btn>
          </v-card>
          <v-card class="error--text text-center mx-auto mt-4" max-width="414" elevation="0" v-if="error">
            <span>{{ $t('error.' + error) }}</span>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { rules } from '@/helpers/validator-rules';
export default {
  name: 'ForgotPasswordView',
  data() {
    return {
      form: {
        valid: false,
        email: null,
      },
      resetRequested: false,
      error: null,
      rules,
      loading: false
    };
  },
  computed: {
    user() {
      return this.$store.getters['user/authUser'];
    },
  },
  methods: {
    navigateTo(link) {
      this.$router.push(link);
    },
    reset() {
      if (this.form.valid) {
        this.resetRequested = false;
        this.error = null;
        this.loading = true;
        this.$store
          .dispatch('user/resetPassword', {
            email: this.form.email,
          }, { root: true})
          .then(() => this.resetRequested = true)
          .catch((error) => (this.error = error.code))
          .finally(() => this.loading = false);
      } else {
        this.$refs.form.validate();
      }
    },
  },
};
</script>
