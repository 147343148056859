<template>
  <v-app v-if="isIndexedDBSupported">
    <v-navigation-drawer app v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" v-if="authUser && authUser.uid">
      <v-list>
        <v-list-item-group mandatory v-model="activeMenuItem" color="primary">
          <div v-for="item in menuItems" :key="item.name">
            <v-list-item link @click.prevent="go(item.link)" v-if="isListItemVisible(item)">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.title" />
              </v-list-item-content>
            </v-list-item>
            <v-divider class="my-2" v-if="item.dividerAfter"></v-divider>
          </div>
        </v-list-item-group>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-item link @click.prevent="signout">
          <v-list-item-icon>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="$t('logout')" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-progress-circular v-if="init" :size="50" color="amber" indeterminate></v-progress-circular>
      <router-view />
    </v-main>

    <v-snackbar v-model="snackbar.visible">
      {{ snackbar.text }}
      <v-btn color="pink" text @click="closeSnackbar()">{{ $t('ok') }}</v-btn>
    </v-snackbar>
  </v-app>
  <div v-else>Nem támogatott böngésző</div>
</template>
<script>
import menuItems from './router/menu-items';
import { log } from './helpers/logger';

export default {
  name: 'App',
  data: () => ({
    drawer: true,
    menuItems,
    activeMenuItem: 1,
    snackbar: {
      visible: false,
      text: null,
      messages: {
        success: 'Sikeres művelet',
      },
    },
    isIndexedDBSupported: false,
  }),
  computed: {
    authUser() {
      return this.$store.getters['user/authUser'];
    },
    userInfo() {
      return this.$store.getters['user/userInfo'];
    },
    init() {
      return this.$store.getters.init;
    },
    drawerVisible() {
      return this.$store.getters.drawerVisible;
    },
    snackbarState() {
      return this.$store.getters.snackbar;
    },
  },
  watch: {
    authUser(user) {
      if (user && user.uid && this.isIndexedDBSupported) {
        this.$store.dispatch('address/fetchAddresses');
        this.$store.dispatch('event/fetchEvents');
        this.$store.dispatch('eventGroup/fetchEventGroups');
        this.$store.dispatch('eventType/fetchEventTypes');
        this.$store.dispatch('instrument/fetchInstruments');
        this.$store.dispatch('role/fetchRoles');
        this.$store.dispatch('schoolDistrict/fetchSchoolDistricts');
        this.$store.dispatch('user/fetchUsers');
        this.$store.dispatch('user/fetchUserRoles');
      }
    },
    userInfo(data) {
      if (data) {
        this.menuItems[0].title = data.lastname + ' ' + data.firstname;
      }
    },
    drawerVisible(bool) {
      this.drawer = bool;
    },
    snackbarState(snackbar) {
      console.log(snackbar);
      if (snackbar?.text) {
        this.showSnackbar(snackbar.text, snackbar.duration ?? 0);
      }
    }
  },
  methods: {
    isListItemVisible(item) {
      return item.permission.some((permittedRole) => this.authUser.userRoles?.includes(permittedRole));
    },
    go(link) {
      this.$router.push(link);
    },
    signout() {
      log({ type: 'logout', userId: this.authUser.uid, email: this.authUser.email });
      this.$store.dispatch('user/signout');
      this.activeMenuItem = 1;
    },
    showSnackbar(text, duration = 3000) {
      setTimeout(() => {
        this.snackbar.visible = true;
        this.snackbar.text = text;
      }, duration);
    },
    closeSnackbar() {
      this.snackbar.visible = false;
      this.snackbar.text = null;
    },
    clientWidth() {
      return Math.max(window.innerWidth, document.documentElement.clientWidth);
    },
    clientHeight() {
      return Math.max(window.innerHeight, document.documentElement.clientHeight);
    },
  },
  beforeMount() {
    const activeRoute = this.$router.currentRoute.name;
    const index = this.menuItems.findIndex((item) => item.name === activeRoute);
    if (index >= 0) {
      this.activeMenuItem = index;
    }
  },
  mounted() {
    this.isIndexedDBSupported = !!window.indexedDB;
  },
};
</script>
<style>
.list-item {
  /* width: 250px; */
  /* white-space: nowrap; */
  white-space: pre-line;
  overflow: hidden;
}
.expansion-panel-header {
  padding: 6px 18px 6px 6px;
}
.chips {
  display: flex;
  flex-wrap: wrap;
}
.v-chip .v-avatar {
  width: 25px !important;
}
.valign-center {
  display: flex;
  align-items: center;
}
</style>
