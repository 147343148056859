import * as firebase from 'firebase';
import { readAllFromIndexedDB, writeToIndexedDB } from '@/services/indexedDB';
import { listener } from '@/services/firebase';

let unsubscribe;

export default {
  namespaced: true,
  state: {
    instruments: [],
    instrumentsLoading: false,
  },
  actions: {
    async fetchInstruments({ commit }) {
      commit('setInstrumentsLoading', true);

      const { data: initialData, lastUpdateTime } = await readAllFromIndexedDB('instruments');
      commit('addInstruments', initialData);

      const collectionRef = firebase.firestore().collection('instruments');
      const query = collectionRef.orderBy('updateTime');

      const callback = async (snapshot) => {
        console.log('snap/inst', snapshot.docs.length);
        if (snapshot.docs.length === 0) {
          commit('setInstrumentsLoading', false);
          return;
        };

        snapshot.docs.forEach(async (doc) => {
          await writeToIndexedDB('instruments', { id: doc.id, ...doc.data() });
        });
        const { data, lastUpdateTime } = await readAllFromIndexedDB('instruments');
        commit('addInstruments', data);
        commit('setInstrumentsLoading', false);
        unsubscribe();
        unsubscribe = listener(query.where('updateTime', '>', lastUpdateTime), callback);
      }

      unsubscribe = listener(lastUpdateTime ? query.where('updateTime', '>', lastUpdateTime) : query, callback);
    },
    unsubscribe({ commit }) {
      unsubscribe();
    }
  },
  mutations: {
    addInstruments(state, payload) {
      state.instruments = payload;
    },
    setInstrumentsLoading(state, payload) {
      state.instrumentsLoading = payload;
    },
  },
  getters: {
    orderedInstruments(state) {
      return state.instruments?.sort((a, b) => a.name?.localeCompare(b.name)) ?? [];
    },
    instruments(state, getters) {
      return getters.orderedInstruments.filter(item => !item.deleted);
    },
    instrumentsInclDeleted(state, getters) {
      return getters.orderedInstruments;
    },
    instrumentsLoading(state) {
      return state.instrumentsLoading;
    },
  }
}
