import * as firebase from 'firebase';
import { isBetweenDates } from '../../../helpers/datetime.helper';
import { readAllFromIndexedDB, writeToIndexedDB } from '@/services/indexedDB';
import { listener } from '@/services/firebase';

let unsubscribe;

export default {
  namespaced: true,
  state: {
    eventGroups: [],
    eventGroupsloading: false,
    selectedUser: null,
  },
  actions: {
    async fetchEventGroups({ commit }) {
      commit('setEventGroupsLoading', true);

      const { data: initialData, lastUpdateTime: initialLastUpdateTime } = await readAllFromIndexedDB('eventGroups');
      console.log('evg/lut', initialLastUpdateTime);
      commit('addEventGroups', initialData);

      const collectionRef = firebase.firestore().collection('eventGroups');
      const query = collectionRef.orderBy('updateTime');

      const callback = async (snapshot) => {
        console.log('snap/evg', snapshot.docs.length);
        if (snapshot.docs.length === 0) {
          commit('setEventGroupsLoading', false);
          return;
        };

        snapshot.docs.forEach(async (doc) => {
          await writeToIndexedDB('eventGroups', { id: doc.id, ...doc.data() });
        });
        const { data, lastUpdateTime } = await readAllFromIndexedDB('eventGroups');
        commit('addEventGroups', data);
        commit('setEventGroupsLoading', false);
        unsubscribe();
        unsubscribe = listener(query.where('updateTime', '>', lastUpdateTime), callback);
      }

      unsubscribe = listener(initialLastUpdateTime ? query.where('updateTime', '>', initialLastUpdateTime) : query, callback);
    },
    setEventGroupFilterTerm({ commit }, payload) {
      commit('setEventGroupFilterTerm', payload);
    },
    unsubscribe({ commit }) {
      unsubscribe();
    }
  },
  mutations: {
    addEventGroups(state, payload) {
      state.eventGroups = payload;
    },
    setEventGroupsLoading(state, payload) {
      state.eventGroupsLoading = payload;
    },
    setEventGroupFilterTerm(state, payload) {
      if (payload) {
        if (payload === '0') {
          state.selectedUser = null;
        } else {
          state.selectedUser = payload;
        }
      }
    },
  },
  getters: {
    groups(state) {
      return state.eventGroups ?? [];
    },
    groupsByDate: (state, getters, rootState, rootGetters) => (date) => {
      if (rootGetters['user/authUser']?.uid && state.eventGroups.length > 0) {
        const selectedUser = state.selectedUser;
        return state.eventGroups
          .filter((group) => !group.deleted)
          .filter((group) => {
            if (group.endDate) {
              return isBetweenDates(date, group.startDate, group.endDate);
            } else {
              return group.startDate === date;
            }
          })
          .filter((group) => {
            const userId = rootGetters['user/authUser'].uid;
            const participants = group.participants;
            if (rootGetters['user/authUser']?.userRoles.some((role) => ['musician', 'performer'].includes(role))) {
              if (participants.length == 0) {
                return false;
              }
              return participants.some((participant) => participant.userId == userId);
            } else if (selectedUser) {
              return participants.some((participant) => participant.userId == selectedUser);
            } else {
              return true;
            }
          });
      } else {
        return [];
      }
    },
    groupById: (state, getters, rootState, rootGetters) => id => {
      if (!id) return;

      const group = state.eventGroups?.find(group => group.id == id);
      console.log('group by id', group);
      if (!group) return;

      group.events = group.events
        .filter(eventId => {
          const event = rootGetters['event/eventById'](eventId);
          return !!event;
        })
        .map(eventId => rootGetters['event/eventById'](eventId))
        .sort((a, b) => {
          const dt1 = new Date(a.date + ' ' + a.startTime).getTime();
          const dt2 = new Date(b.date + ' ' + b.startTime).getTime();
          return dt1 > dt2 ? 1 : -1;
        })
        .map(event => event.id);
      return group;
    },
  },
  eventGroupsLoading(state) {
    return state.eventGroups.loading;
  },
  selectedUser(state) {
    return state.selectedUser;
  }
}
