import * as firebase from 'firebase';
import { readAllFromIndexedDB, writeToIndexedDB } from '@/services/indexedDB';
import { listener } from '@/services/firebase';

let unsubscribe;

export default {
  namespaced: true,
  state: {
    schoolDistricts: [],
    schoolDistrictsLoading: false,
  },
  actions: {
    async fetchSchoolDistricts({ commit }) {
      commit('setSchoolDistrictsLoading', true);

      const { data: initialData, lastUpdateTime } = await readAllFromIndexedDB('schoolDistricts');
      commit('addSchoolDistricts', initialData);

      const collectionRef = firebase.firestore().collection('schoolDistricts');
      const query = collectionRef.orderBy('updateTime');

      const callback = async (snapshot) => {
        console.log('snap/schd', snapshot.docs.length);
        if (snapshot.docs.length === 0) {
          commit('setSchoolDistrictsLoading', false);
          return;
        };

        snapshot.docs.forEach(async (doc) => {
          await writeToIndexedDB('schoolDistricts', { id: doc.id, ...doc.data() });
        });
        const { data, lastUpdateTime } = await readAllFromIndexedDB('schoolDistricts');
        commit('addSchoolDistricts', data);
        commit('setSchoolDistrictsLoading', false);
        unsubscribe();
        unsubscribe = listener(query.where('updateTime', '>', lastUpdateTime), callback);
      }

      unsubscribe = listener(lastUpdateTime ? query.where('updateTime', '>', lastUpdateTime) : query, callback);
    },
    unsubscribe({ commit }) {
      unsubscribe();
    }
  },
  mutations: {
    addSchoolDistricts(state, payload) {
      state.schoolDistricts = payload;
    },
    setSchoolDistrictsLoading(state, payload) {
      state.schoolDistrictsLoading = payload;
    },
  },
  getters: {
    orderedSchoolDistricts(state) {
      return state.schoolDistricts?.sort((a, b) => a.name?.localeCompare(b.name)) ?? [];
    },
    schoolDistricts(state, getters) {
      return getters.orderedSchoolDistricts.filter((item) => !item.deleted).map(sd => {
        const { name, id } = sd;
        return { name, id };
      });
    },
    schoolDistrictById: (state, getters) => (id) => {
      return getters.orderedSchoolDistricts.find((item) => item.id === id);
    },
    schoolDistrictsInclDeleted(state, getters) {
      return getters.orderedSchoolDistricts;
    },
    schoolDistrictsLoading(state) {
      return state.schoolDistricts.loading;
    },
  }
}
