import i18n from '../i18n'
export default [
  {
    title: '',
    icon: 'mdi-account-circle-outline',
    link: '/profil',
    name: 'profil',
    permission: ['admin', 'editor', 'viewer', 'performer', 'musician'],
    dividerAfter: true
  },
  {
    title: i18n.t('events'),
    icon: 'mdi-calendar-text',
    link: '/',
    name: 'home',
    permission: ['admin', 'editor', 'viewer', 'performer', 'musician']
  },
  {
    title: i18n.t('event-types'),
    icon: 'mdi-calendar-question',
    link: '/eloadas-fajtak',
    name: 'eloadas-fajtak',
    permission: ['admin', 'editor']
  },
  {
    title: i18n.t('stat'),
    icon: 'mdi-chart-line',
    link: '/kimutatas',
    name: 'kimutatas',
    permission: ['admin', 'editor', 'viewer', 'performer', 'musician']
  },
  {
    title: i18n.t('contacts'),
    icon: 'mdi-account-outline',
    link: '/nevjegyzek',
    name: 'nevjegyzek',
    permission: ['admin', 'editor']
  },
  {
    title: i18n.t('instruments'),
    icon: 'mdi-trumpet',
    link: '/hangszerek',
    name: 'hangszerek',
    permission: ['admin', 'editor']
  },
  {
    title: i18n.t('places'),
    icon: 'mdi-map-search-outline',
    link: '/helyszinek',
    name: 'helyszinek',
    permission: ['admin', 'editor', 'viewer']
  },
  {
    title: i18n.t('school-districts'),
    icon: 'mdi-directions',
    link: '/tankeruletek',
    name: 'tankeruletek',
    permission: ['admin', 'editor']
  }
];
