<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="pl-0 pr-4 py-2">
      <v-list-item class="list-item">
        <v-list-item-content>
          <v-list-item-title>{{ address.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ address.zip }} {{ address.city }}, {{ address.address }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="body-2">
      <v-list dense class="pt-0">
        <v-list-item class="pa-0" v-if="address">
          <v-list-item-icon class="py-1 mr-4"> <v-icon small>mdi-map-marker-outline</v-icon> </v-list-item-icon>
          <v-list-item-content class="pa-0">
            <a
              :href="'https://www.google.com/maps/dir//' + address.zip + ' ' + address.city + ',' + address.address"
              target="_blank"
              >{{ $t('display-on-map') }}</a
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="px-0" v-if="address && address.contactName">
          <v-list-item-icon class="py-1 mr-4"> <v-icon small>mdi-account-box-outline</v-icon> </v-list-item-icon>
          <v-list-item-content class="pa-0">{{ address.contactName }}</v-list-item-content>
        </v-list-item>
        <v-list-item class="pa-0" v-if="address && address.contactTelephone">
          <v-list-item-icon class="py-1 mr-4"> <v-icon small>mdi-phone-outline</v-icon> </v-list-item-icon>
          <v-list-item-content>
            <a :href="'tel:' + address.contactTelephone">{{ address.contactTelephone }}</a>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="pa-0" v-if="address && address.contactEmail">
          <v-list-item-icon class="py-1 mr-4"> <v-icon small>mdi-email-outline</v-icon> </v-list-item-icon>
          <v-list-item-content>
            <a :href="'mailto:' + address.contactEmail">{{ address.contactEmail }}</a>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="pa-0" v-if="address && address.note">
          <v-list-item-icon class="py-1 mr-4"> <v-icon small>mdi-note-outline</v-icon> </v-list-item-icon>
          <v-list-item-content>
            <span>{{ address.note }}</span>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider class="my-4" v-if="withActionButtons"></v-divider>
      <v-row v-if="withActionButtons">
        <v-spacer></v-spacer>
        <v-col cols="auto" class="pa-0">
          <v-btn color="blue darken-1" text @click="edit()">{{ $t('edit') }}</v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
export default {
  name: 'AddressListItem',
  props: ['id', 'withActionButtons'],
  computed: {
    address() {
      return this.id && this.$store.getters['address/addressById'](this.id)?.data;
    },
    schoolDistrict() {
      return (
        this.address &&
        this.address.schoolDistrictId &&
        this.$store.getters['schoolDistrict/schoolDistrictById'](this.address.schoolDistrictId)?.data
      );
    },
  },
  methods: {
    edit() {
      this.$emit('edit');
    },
  },
};
</script>
