<template>
  <tr>
    <td class="pa-2 pl-0">
      <v-autocomplete
        v-model="participantObj.userId"
        class="no-hint"
        outlined
        clearable
        :label="getAutocompleteLabel"
        :items="filteredUsers"
        :item-text="(item) => item.lastname + ' ' + item.firstname"
        item-value="id"
        @change="emitData()"
      />
    </td>
    <td class="pa-2 pl-4 pr-0">
      <v-switch
        inset
        v-model="participantObj.isDriver"
        :disabled="!participantObj.userId"
        @change="emitData()"
      />
    </td>
  </tr>
</template>

<script>
export default {
  name: 'ParticipantListItem',
  props: ['role', 'instrument', 'participant'],
  data() {
    return {
      participantObj: {
        confirmed: null,
        isDriver: false,
        roleId: this.role?.id,
        instrumentId: this.instrument?.id,
        userId: null
      },
      userIdBefore: null,
    };
  },
  computed: {
    users() {
      return this.$store.getters['user/usersWithParticipantRoles'];
    },
    filteredUsers() {
      return this.getFilteredUsers(this.role?.id, this.instrument?.id);
    },
    getAutocompleteLabel() {
      return this.role?.id === 'performer' ? this.role?.name : this.instrument?.name ?? '';
    },
  },
  mounted() {
    if (this.participant) {
      this.participantObj = { ...this.participant };
      this.userIdBefore = this.participant.userId;
    }
  },
  methods: {
    getFilteredUsers(roleId, instrumentId) {
      const usersFilteredByRole = this.users?.filter((user) => user.userRoles?.includes(roleId)) ?? [];
      if (instrumentId) {
        return usersFilteredByRole.filter((user) => user.instruments?.includes(instrumentId));
      } else {
        return usersFilteredByRole;
      }
    },
    emitData() {
      if (!this.participantObj.instrumentId) delete this.participantObj.instrumentId;
      if (!this.participantObj.userId) this.participantObj.isDriver = false;

      this.$emit('change', { participant: this.participantObj, userIdBefore: this.userIdBefore });

      this.userIdBefore = this.participantObj.userId;
    },
  },
};
</script>
<style>
.no-hint .v-text-field__details {
  display: none;
}
.no-hint .v-input__slot {
  margin-bottom: 0;
}
</style>
