import * as firebase from 'firebase';
import { readAllFromIndexedDB, writeToIndexedDB } from '@/services/indexedDB';
import { listener } from '@/services/firebase';

let unsubscribe;
const addressSearchFields = ['name', 'city', 'address'];

export default {
  namespaced: true,
  state: {
    addresses: [],
    addressCount: 0,
    addressSearchTerm: '',
    addressLoading: false,
  },
  actions: {
    async fetchAddresses({ commit }) {
      commit('setAddressLoading', true);

      const { data: initialData, lastUpdateTime: initialLastUpdateTime } = await readAllFromIndexedDB('addresses');
      commit('addAddresses', initialData);

      const collectionRef = firebase.firestore().collection('addresses');
      const query = collectionRef.orderBy('updateTime');

      const callback = async (snapshot) => {
        console.log('snap/addr', snapshot.docs.length);
        if (snapshot.docs.length === 0) {
          commit('setAddressLoading', false);
          return;
        };

        snapshot.docs.forEach(async (doc) => {
          await writeToIndexedDB('addresses', { id: doc.id, ...doc.data() });
        });
        const { data, lastUpdateTime } = await readAllFromIndexedDB('addresses');
        commit('addAddresses', data);
        commit('setAddressLoading', false);
        unsubscribe();
        unsubscribe = listener(query.where('updateTime', '>', lastUpdateTime), callback);
      }

      unsubscribe = listener(initialLastUpdateTime ? query.where('updateTime', '>', initialLastUpdateTime) : query, callback);
    },
    setAddressSearchTerm({ commit }, payload) {
      commit('setAddressSearchTerm', payload);
    },
    unsubscribe({ commit }) {
      unsubscribe();
    }
  },
  mutations: {
    addAddresses(state, payload) {
      state.addresses = payload;
    },
    setAddressSearchTerm(state, payload) {
      state.addressSearchTerm = payload?.toString().toLowerCase() ?? null;
    },
    setAddressLoading(state, payload) {
      state.addressLoading = payload;
    },
  },
  getters: {
    orderedAddresses(state) {
      return state.addresses?.sort((a, b) => a.name?.localeCompare(b.name)) ?? [];
    },
    undeletedAddresses(state, getters) {
      return getters.orderedAddresses.filter(item => !item.deleted);
    },
    filteredAddresses: (state, getters) => {
      return getters.undeletedAddresses.filter(item => {
        return state.addressSearchTerm ? addressSearchFields.some((key) => {
          if (!item[key]) return false;
          const string = item[key].toString().toLowerCase();
          return string.length > 0 ? string.includes(state.addressSearchTerm) : false;
        }) : item;
      });
    },
    addresses: (state, getters) => {
      return getters.filteredAddresses ?? [];
    },
    addressesSubset: (state, getters) => (page) => {
      const start = (page - 1) * 10;
      const stop = start + 10;
      return getters.filteredAddresses?.slice(start, stop) ?? [];
    },
    addressCount: (state, getters) => {
      return getters.filteredAddresses?.length ?? 0;
    },
    addressLoading(state) {
      return state.addressLoading;
    },
    addressById: (state) => (id) => {
      return state.addresses.find((item) => item.id === id);
    },
  }
}
