<template>
  <v-dialog persistent scrollable :value="visible" max-width="500">
    <v-form v-model="formValid" ref="form">
      <v-card v-if="!confirmDialogVisible">
        <v-card-title>
          <span class="headline">{{ form.id ? $t('edit') : $t('add-new') }}</span> <v-spacer></v-spacer>
          <v-btn icon @click="close()"> <v-icon>mdi-close</v-icon> </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <v-text-field
                dense
                :label="$t('last-name')"
                v-model="form.data.lastname"
                required
                :rules="[rules.required]"
                validate-on-blur
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                dense
                :label="$t('first-name')"
                v-model="form.data.firstname"
                required
                :rules="[rules.required]"
                validate-on-blur
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                dense
                :label="$t('email')"
                v-model="form.data.email"
                required
                :rules="[rules.required, rules.email]"
                :disabled="!!id"
                validate-on-blur
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                dense
                v-mask="mask.telephone"
                :label="$t('telephone')"
                v-model="form.data.telephone"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                dense
                v-model="form.data.userRoles"
                :items="userRoles"
                item-text="name"
                item-value="id"
                :label="$t('role')"
                required
                :rules="[rules.required, rules.notEmptyArray]"
                validate-on-blur
                multiple
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="form.data.userRoles?.includes('musician')">
            <v-col cols="12">
              <v-select
                dense
                v-model="form.data.instruments"
                :items="instruments"
                item-text="name"
                item-value="id"
                :label="$t('instruments')"
                multiple
                required
                :rules="[rules.notEmptyArray]"
                validate-on-blur
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mt-4 mt-md-0" v-if="error">
            <v-col cols="12" class="text-center">
              <span class="red--text">{{ $t('error.' + error) }}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text v-if="form.id" @click="confirmDialogVisible = true">{{
            $t('delete')
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text v-bind:loading="loading" @click="save()">{{ $t('save') }}</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="confirmDialogVisible">
        <v-card-text class="confirm-text">{{ $t('confirm-to-delete') }}</v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="remove()" v-bind:loading="deleting">{{ $t('delete') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="confirmDialogVisible = false">{{ $t('cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import * as firebase from 'firebase';
import { rules } from '../../helpers/validator-rules';
import { mask } from 'vue-the-mask';
export default {
  name: 'UserDialog',
  props: ['visible', 'id'],
  directives: { mask },
  data() {
    return {
      form: {
        id: null,
        data: {
          firstname: null,
          lastname: null,
          email: null,
          telephone: null,
          userRoles: [],
          instruments: [],
          deleted: false,
        },
      },
      loading: false,
      deleting: false,
      error: null,
      formValid: false,
      confirmDialogVisible: false,
      mask: { telephone: '+36 ## ### ####' },
      rules,
    };
  },
  computed: {
    user() {
      return this.id && this.$store.getters['user/userById'](this.id);
    },
    userRoles() {
      return this.$store.getters['user/userRoles'];
    },
    instruments() {
      return this.$store.getters['instrument/instruments'];
    },
  },
  methods: {
    save() {
      if (this.formValid) {
        this.loading = true;
        this.error = null;

        if (this.form.data.instruments.length === 0) {
          delete this.form.data.instruments;
        }

        if (this.form.id) {
          // update user
          const app = firebase.app();
          const functions = app.functions("europe-west3");
          const query = functions.httpsCallable('updateUser');
          query({ id: this.form.id, data: { ...this.form.data, updateTime: new Date().toISOString() }})
            .then((result) => {
              this.loading = false;
              this.close();
            })
            .catch((error) => {
              this.loading = false;
              this.error = error.toString().substr(7);
            });
        } else {
          // add new user
          const app = firebase.app();
          const functions = app.functions("europe-west3");
          const query = functions.httpsCallable('addUser');
          query({ ...this.form.data, createTime: new Date().toISOString(), updateTime: new Date().toISOString() })
            .then((result) => {
              this.loading = false;
              this.close();
            })
            .catch((error) => {
              this.loading = false;
              this.error = error.toString().substr(7);
            });
        }
      } else {
        this.$refs.form.validate();
      }
    },
    remove() {
      this.deleting = true;
      const app = firebase.app();
      const functions = app.functions("europe-west3");
      const query = functions.httpsCallable('deleteUser');
      deleteUser(this.form.id)
        .then(() => {
          this.deleting = false;
          this.close();
        })
        .catch((error) => {
          this.deleting = false;
          this.showSnackbar(error.toString().substr(7));
          this.close();
        });
    },
    close() {
      this.$emit('close');
    },
    showSnackbar(text) {
      this.$store.dispatch('setSnackbar', { text }, { root: true });
    },
  },
  created() {
    if (this.id && this.user) {
      const { id, ...data } = this.user;
      this.form.id = this.id;
      this.form.data = JSON.parse(JSON.stringify(data));
      if (this.form.data.instruments === undefined) this.form.data.instruments = [];
    }
  },
};
</script>
