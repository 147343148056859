import * as firebase from 'firebase';
import { readAllFromIndexedDB, writeToIndexedDB } from '@/services/indexedDB';
import { listener } from '@/services/firebase';

let unsubscribe;

export default {
  namespaced: true,
  state: {
    roles: [],
    reolesLoading: false,
  },
  actions: {
    async fetchRoles({ commit }) {
      commit('setRolesLoading', true);

      const { data: initialData, lastUpdateTime } = await readAllFromIndexedDB('roles');
      commit('addRoles', initialData);

      const collectionRef = firebase.firestore().collection('roles');
      const query = collectionRef.orderBy('updateTime');

      const callback = async (snapshot) => {
        console.log('snap/rol', snapshot.docs.length);
        if (snapshot.docs.length === 0) {
          commit('setRolesLoading', false);
          return;
        };

        snapshot.docs.forEach(async (doc) => {
          await writeToIndexedDB('roles', { id: doc.id, ...doc.data() });
        });
        const { data, lastUpdateTime } = await readAllFromIndexedDB('roles');
        commit('addRoles', data);
        commit('setRolesLoading', false);
        unsubscribe();
        unsubscribe = listener(query.where('updateTime', '>', lastUpdateTime), callback);
      }

      unsubscribe = listener(lastUpdateTime ? query.where('updateTime', '>', lastUpdateTime) : query, callback);
    },
    unsubscribe({ commit }) {
      unsubscribe();
    }
  },
  mutations: {
    addRoles(state, payload) {
      state.roles = payload;
    },
    setRolesLoading(state, payload) {
      state.rolesLoading = payload;
    },
  },
  getters: {
    orderedRoles(state) {
      return state.roles?.sort((a, b) => a.name?.localeCompare(b.name)) ?? [];
    },
    roles(state, getters) {
      return getters.orderedRoles.filter(
        (item) => !item.deleted && !Object.prototype.hasOwnProperty.call(item, 'isAdmin')
      );
    },
    rolesInclDeleted(state, getters) {
      return getters.orderedRoles.filter((item) => !Object.prototype.hasOwnProperty.call(item, 'isAdmin'));
    },
    rolesLoading(state) {
      return state.rolesLoading;
    },
    // performerRoles(state) {
    //   return state.roles
    //     .filter(
    //       (item) =>
    //         !item.deleted &&
    //         Object.prototype.hasOwnProperty.call(item, 'isPerformer') &&
    //         item.isPerformer
    //     )
    //     .sort((a, b) => a.order - b.order);
    // },
  }
}
