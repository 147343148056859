<template>
  <v-app>
    <v-app-bar app flat :clipped-left="$vuetify.breakpoint.lgAndUp" clipped-right color="blue darken-3" dark>
      <v-app-bar-nav-icon @click.stop="toggleDrawer()" />
      <v-toolbar-title class="hidden-sm-and-down ml-0 px-4">{{ $t('stat') }}</v-toolbar-title>
      <v-spacer class="hidden-sm-and-down" />
      <v-text-field
        class="hidden-sm-and-down"
        v-if="user && user.role !== 'performer'"
        flat
        solo-inverted
        hide-details
        prepend-inner-icon="mdi-magnify"
        :label="$t('search')"
        clearable
        v-model="searchTerm"
        @click:clear="searchTerm = ''"
      />
      <v-spacer />
      <v-toolbar-title class="mx-4">{{ formatDate(monthpicker, 'YYYY. MMMM') }}</v-toolbar-title>
      <v-btn icon @click.stop="monthpicker = subtractMonth(monthpicker, 1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn icon @click.stop="monthpicker = addMonth(monthpicker, 1)">
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
      <v-btn icon @click.stop="drawerRight = !drawerRight">
        <v-icon>mdi-calendar</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawerRight" clipped app right width="300">
      <v-btn class="hidden-md-and-up ma-4 mr-2" icon @click.stop="drawerRight = !drawerRight">
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
      <span class="hidden-md-and-up">{{ $t('close') }}</span>
      <v-skeleton-loader :loading="loadingState" type="image">
        <v-date-picker class="date-picker" no-title v-model="monthpicker" type="month" full-width></v-date-picker>

        <v-divider v-if="user && user.role !== 'performer'"></v-divider>

        <v-list rounded v-if="user && user.role !== 'performer'">
          <v-subheader>{{ $t('download-data') }}</v-subheader>
          <v-list-item-group color="primary">
            <v-list-item v-for="(item, i) in csvExportButtons" :key="i" v-on:click="download(i + 1)">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-skeleton-loader>
    </v-navigation-drawer>

    <v-container>
      <v-row class="mx-3">
        <v-col cols="12">
          <v-skeleton-loader :loading="loadingState" type="table-tbody">
            <v-text-field
              class="hidden-md-and-up mt-4"
              v-if="user && user.role !== 'performer'"
              flat
              solo-inverted
              hide-details
              prepend-inner-icon="mdi-magnify"
              :label="$t('search')"
              clearable
              v-model="searchTerm"
              @click:clear="searchTerm = ''"
            />
            <v-data-table
              v-if="data"
              :headers="headers"
              :items="data"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              hide-default-footer
              :search="searchTerm"
              class="elevation-0"
              sort-by="userFullName"
              @page-count="pageCount = $event"
            >
              <template v-slot:item.kms="{ item }">
                <span>{{ item.kms.toLocaleString('hu-HU') }}</span>
              </template>
            </v-data-table>

            <div class="table-footer mt-5" v-if="user && user.role !== 'performer'">
              <v-spacer></v-spacer>
              <v-pagination
                v-model="page"
                :length="pageCount"
              ></v-pagination>
              <v-spacer></v-spacer>
              <v-select
                class="table-footer__ipp-selector"
                v-model="itemsPerPage"
                :items="[25, 50, 200]"
                solo
              />
            </div>
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import { addMonth, subtractMonth, formatDate } from '../helpers/datetime.helper';
export default {
  name: 'StatView',
  data: () => ({
    drawerRight: null,
    page: 1,
    pageCount: 0,
    itemsPerPage: 50,
    headers: [
      { text: 'Név', value: 'userFullName', align: 'start', width: 35 },
      {
        text: 'Előadások (db)',
        value: 'numConfirmedEvents',
        align: 'end',
        width: 15,
      },
      {
        text: 'Előadások - nem elfogadott (db)',
        value: 'numUnconfirmedEvents',
        align: 'end',
        width: 15,
      },
      {
        text: 'Autóhasználat (km)',
        value: 'kms',
        align: 'end',
        width: 15,
      },
    ],
    searchTerm: '',
    monthpicker: new Date().toISOString().substr(0, 7),
    csvExportButtons: [
      { text: 'Előadások', icon: 'mdi-file-download-outline' },
      { text: 'Kimutatás', icon: 'mdi-file-download-outline' },
    ],
  }),
  computed: {
    loadingState() {
      return this.$store.getters.loadingState;
    },
    data() {
      return this.$store.getters.statByMonth(this.monthpicker);
    },
    user() {
      return this.$store.getters['user/authUser'];
    },
    eventsCsvData() {
      return this.$store.getters.eventsByMonthAsCSV(this.monthpicker);
    },
    statCsvData() {
      return this.$store.getters.statByMonthAsCSV(this.monthpicker);
    },
  },
  methods: {
    toggleDrawer() {
      this.$store.dispatch('toggleDrawer', null, { root: true });
    },
    addMonth(date, months) {
      return addMonth(date, months);
    },
    subtractMonth(date, months) {
      return subtractMonth(date, months);
    },
    formatDate(date, format) {
      return formatDate(date, format);
    },
    download(type) {
      const data = type === 1 ? this.eventsCsvData : this.statCsvData;
      const filename = 'bftz_' + (type === 1 ? 'eloadasok_' : 'stat_') + this.monthpicker + '.csv';
      let csvContent = data.map((e) => e.join(',')).join('\n');
      var link = document.createElement('a');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvContent));
      link.setAttribute('download', filename);
      document.body.appendChild(link); // required for FF
      link.click(); // this will download the file
    },
  },
  watch: {
    monthpicker(value) {
      // reset page to 1 if another month is selected
      this.page = 1;
    }
  },
};
</script>

<style lang="scss">
.table-footer {
  display: flex;
  align-items: center;

  &__ipp-selector {
    flex-grow: 0;
    max-width: 7rem;
  }

  .v-input__slot {
    margin: 0;
  }

  .v-text-field__details {
    display: none;
  }
}
</style>
