import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import Profile from '../views/ProfileView.vue'
import UsersView from '../views/UsersView.vue'
import InstrumentsView from '../views/InstrumentsView.vue'
import SchoolDistrictsView from '../views/SchoolDistrictsView.vue'
import Addresses from '../views/AddressesView.vue'
import StatView from '../views/StatView.vue'
import PageNotFoundView from '../views/PageNotFoundView.vue'
import NoRightsView from '../views/NoRightsView.vue'
import ForgotPasswordView from '../views/ForgotPasswordView.vue'
import EventTypeView from '../views/EventTypeView.vue'
import * as firebase from 'firebase';

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: {
            title: 'Cetli'
        }
    },
    {
        path: '/profil',
        name: 'profil',
        component: Profile,
        meta: {
            title: 'Cetli',
            requiresAuth: true,
            permission: ['admin', 'editor', 'viewer', 'performer', 'musician']
        }
    },
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            title: 'Cetli',
            requiresAuth: true,
            permission: ['admin', 'editor', 'viewer', 'performer', 'musician']
        }
    },
    {
        path: '/eloadas-fajtak',
        name: 'eloadas-fajtak',
        component: EventTypeView,
        meta: {
            title: 'Cetli',
            requiresAuth: true,
            permission: ['admin', 'editor']
        }
    },
    {
        path: '/nevjegyzek',
        name: 'nevjegyzek',
        component: UsersView,
        meta: {
            title: 'Cetli',
            requiresAuth: true,
            permission: ['admin', 'editor']
        }
    },
    {
        path: '/hangszerek',
        name: 'hangszerek',
        component: InstrumentsView,
        meta: {
            title: 'Cetli',
            requiresAuth: true,
            permission: ['admin', 'editor']
        }
    },
    {
        path: '/tankeruletek',
        name: 'tankeruletek',
        component: SchoolDistrictsView,
        meta: {
            title: 'Cetli',
            requiresAuth: true,
            permission: ['admin', 'editor']
        }
    },
    {
        path: '/helyszinek',
        name: 'helyszinek',
        component: Addresses,
        meta: {
            title: 'Cetli',
            requiresAuth: true,
            permission: ['admin', 'editor', 'viewer']
        }
    },
    {
        path: '/kimutatas',
        name: 'kimutatas',
        component: StatView,
        meta: {
            title: 'Cetli',
            requiresAuth: true,
            permission: ['admin', 'editor', 'viewer', 'performer', 'musician']
        }
    },
    {
        path: '/elfelejtett-jelszo',
        name: 'elfelejtett-jelszo',
        component: ForgotPasswordView,
        meta: {
            title: 'Cetli'
        }
    },
    {
        path: '/403',
        name: '403',
        component: NoRightsView
    },
    // {
    //     path: '/about',
    //     name: 'about',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // },
    {
        path: '(.*)',
        component: PageNotFoundView
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {

    firebase.auth().onAuthStateChanged(() => {
        const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
        const currentUser = firebase.auth().currentUser

        if (requiresAuth && !currentUser) {
            next('/login')
        } else if (requiresAuth && currentUser) {
            currentUser.getIdTokenResult()
                .then((result) => {
                    if (result.claims &&
                        result.claims.roles &&
                        to.matched.some(record => {
                            return record.meta.permission.some((role) => result.claims.roles.includes(role))
                        })) {
                        next()
                    } else {
                        next({
                            path: '/403',
                        })
                    }

                })
        } else {
            next()
        }
    })

    next()

})

export default router
