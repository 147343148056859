<template>
  <div>
    <v-row class="ma-0 mb-6"> {{ $t('participants') }} </v-row>
    <table>
      <thead>
        <tr>
          <td class="text-left pa-2 pl-0">Név</td>
          <td class="text-left pa-2 pl-4 pr-0">Autó</td>
        </tr>
      </thead>
      <tbody>
        <ParticipantListItem
          v-for="(row, index) in rows.byMusician"
          v-bind:key="index"
          :role="row.role"
          :instrument="row.instrument"
          :participant="row.participant"
          @change="updateParticipantList($event)"
        />
        <ParticipantListItem
          v-for="(row, index) in rows.byPerfomer"
          v-bind:key="index"
          :role="row.role"
          :participant="row.participant"
          @change="updateParticipantList($event)"
        />
      </tbody>
    </table>
  </div>
</template>
<script>
import { getArrayItemById } from '@/helpers/form.helper';
import ParticipantListItem from './ParticipantListItem.vue';

export default {
  name: 'ParticipantList',
  props: ['participants'],
  components: { ParticipantListItem },
  data() {
    return {
      participantList: {},
    };
  },
  computed: {
    participantRoles() {
      return this.$store.getters['user/participantRoles'];
    },
    instruments() {
      return this.$store.getters['instrument/instruments'];
    },
    instrumentsSorted() {
      return [...this.instruments]?.sort((a, b) => a.name - b.name);
    },
    rows() {
      return {
        byMusician: this.instrumentsSorted?.map((instrument, index) => {
          return {
            role: this.roleById('musician'),
            instrument,
            participant: this.getParticipant('musician', instrument.id),
          }
        }),
        byPerfomer: [{
          role: this.roleById('performer'),
          participant: this.getParticipant('performer'),
        }]
      }
    }
  },
  mounted() {
    if (this.participants?.length > 0) this.participants.forEach((p) => this.participantList[p.userId] = p);
  },
  methods: {
    roleById(id) {
      return getArrayItemById(this.participantRoles, id);
    },
    getParticipant(roleId, instrumentId) {
      return this.participants?.find((p) => p.roleId === roleId && (p.instrumentId ? p.instrumentId === instrumentId : true));
    },
    updateParticipantList(payload) {
      if (payload.userIdBefore !== payload.participant.userId) delete this.participantList[payload.userIdBefore];
      if (payload.participant.userId) this.participantList[payload.participant.userId] = { ...payload.participant };

      this.$emit('update:participants', Object.values(this.participantList));
    }
  },
};
</script>
<style>
.no-hint .v-text-field__details {
  display: none;
}
.no-hint .v-input__slot {
  margin-bottom: 0;
}
</style>
