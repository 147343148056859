import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import i18n from './i18n'
import * as firebase from 'firebase';
import { log } from './helpers/logger';

Vue.config.productionTip = false

let app = false;

firebase.initializeApp({
  apiKey: "AIzaSyBVChFB122l5qsDHv6xNNFjsZt4hzFB-yY",
  authDomain: "bftz-cetli.firebaseapp.com",
  databaseURL: "https://bftz-cetli.firebaseio.com",
  projectId: "bftz-cetli",
  storageBucket: "bftz-cetli.appspot.com",
  // messagingSenderId: "62790492039",
  // appId: "1:62790492039:web:e190ef8d061a684fcdfd22",
  // measurementId: "G-R20J2998M1"
});

// firebase utils
const db = firebase.firestore()
const auth = firebase.auth()

// The default cache size threshold is 40 MB. Configure "cacheSizeBytes"
// for a different threshold (minimum 1 MB) or set to "CACHE_SIZE_UNLIMITED"
// to disable clean-up.
db.settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});

db.enablePersistence()
  .catch(function (err) {
    if (err.code == 'failed-precondition') {
      // console.log('enable persistence failed')
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code == 'unimplemented') {
      // console.log('browser does not support persistence')
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });
// Subsequent queries will use persistence, if it was enabled successfully

auth.onAuthStateChanged(response => {
  if (!app) {
    app = true
    new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: h => h(App),
      beforeCreate: function () {
        this.$store.dispatch('setInit', false, { root: true });
      }
    }).$mount('#app');

    // if the app loads and the user is already logged in we want to log
    if (response && response.uid) {
      log({ type: 'refresh', userId: response.uid, email: response.email });
    }
  }

  // after login auth state will change and we want to store the user
  if (response && response.uid) {
    store.dispatch('user/autoSignin', response, { root: true });
  }
});

export {
  db,
  auth
}
