<template>
  <div>
    <v-app-bar flat :clipped-left="$vuetify.breakpoint.lgAndUp" app color="blue darken-3" dark>
      <v-app-bar-nav-icon @click.stop="toggleDrawer()" />
      <v-toolbar-title class="hidden-sm-and-down ml-0 px-4">{{ $t('places') }}</v-toolbar-title>
      <v-spacer />
      <v-text-field
        flat
        solo-inverted
        hide-details
        prepend-inner-icon="mdi-magnify"
        :label="$t('search')"
        clearable
        v-model="searchTerm"
        @keyup="setSearchTerm()"
        @click:clear="clearSearchTerm()"
      />
      <v-spacer />
      <v-btn icon @click.stop="dialogVisible = true" v-if="userCanEdit">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-app-bar>

    <v-container>
      <v-row class="pa-4">
        <v-col cols="12">
          <v-card flat>
            <v-skeleton-loader :loading="loadingState" type="list-item-two-line@10">
              <v-pagination
                v-if="totalPages > 1"
                class="my-6"
                v-model="page"
                :length="totalPages"
                :total-visible="10"
              ></v-pagination>

              <div class="mb-4">Találatok száma: {{ addressCount }}</div>

              <v-expansion-panels v-if="addresses.length > 0" v-model="panel">
                <v-expansion-panel v-for="(item, i) in addresses" :key="i">
                  <v-expansion-panel-header class="expansion-panel-header">
                    <v-list-item class="list-item">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.zip ? item.zip + ' ' : '' }}
                          {{ item.city ? item.city + ' ' : '' }}
                          {{ (item.zip || item.city) ? ', ' : '' }}
                          {{ item.address }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list dense class="pt-0">
                      <v-list-item class="px-0" v-if="item.isSchool">
                        <v-list-item-content>{{ $t('school-district') }}: {{ item.schoolDistrict?.name ?? '' }}</v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pa-0" v-if="item.city && item.address">
                        <v-list-item-icon class="py-1 mr-4">
                          <v-icon small>mdi-map-marker-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="pa-0">
                          <a
                            :href="
                              'https://www.google.com/maps/dir//' +
                              item.zip +
                              ' ' +
                              item.city +
                              ',' +
                              item.address
                            "
                            target="_blank"
                            >{{ $t('display-on-map') }}</a
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="px-0" v-if="item.contactName">
                        <v-list-item-icon class="py-1 mr-4">
                          <v-icon small>mdi-account-box</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="pa-0">{{ item.contactName }}</v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pa-0" v-if="item.contactTelephone">
                        <v-list-item-icon class="py-1 mr-4">
                          <v-icon small>mdi-phone</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <a :href="'tel:' + item.contactTelephone">{{ item.contactTelephone }}</a>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pa-0" v-if="item.contactEmail">
                        <v-list-item-icon class="py-1 mr-4">
                          <v-icon small>mdi-email</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <a :href="'mailto:' + item.contactEmail">{{ item.contactEmail }}</a>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pa-0" v-if="item.url">
                        <v-list-item-icon class="py-1 mr-4">
                          <v-icon small>mdi-link-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <a :href="item.url" target="_blank">{{ item.url }}</a>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pa-0" v-if="item.note">
                        <v-list-item-icon class="py-1 mr-4">
                          <v-icon small>mdi-note-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <span>{{ item.note }}<br /></span>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>

                    <v-divider class="my-4" v-if="userCanEdit"></v-divider>

                    <v-row v-if="userCanEdit">
                      <v-spacer></v-spacer>
                      <v-col cols="auto" class="pa-0">
                        <v-btn color="blue darken-1" text @click="edit(item.id)">{{ $t('edit') }}</v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-list v-else>
                <v-list-item disabled>
                  <v-list-item-content>
                    <v-list-item-title class="list-item-title">{{ $t('no-results') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-pagination
                v-if="totalPages > 1"
                class="my-6"
                v-model="page"
                :length="totalPages"
                :total-visible="10"
              ></v-pagination>
            </v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-address-dialog
      v-if="dialogVisible"
      v-bind:visible="dialogVisible"
      v-bind:id="id"
      v-on:close="closeDialog()"
    ></v-address-dialog>
  </div>
</template>
<script>
import AddressDialog from '@/components/dialog/AdressDialog.vue';

export default {
  name: 'AddressesView',
  components: {
    'v-address-dialog': AddressDialog,
  },
  data: () => ({
    dialogVisible: false,
    id: null,
    page: 1,
    panel: [],
    searchTerm: null,
  }),
  computed: {
    user() {
      return this.$store.getters['user/authUser'];
    },
    userCanEdit() {
      return this.user && ['admin', 'editor'].some((role) => this.user?.userRoles?.includes(role));
    },
    addresses() {
      return this.$store.getters['address/addressesSubset'](this.page);
    },
    addressCount() {
      return this.$store.getters['address/addressCount'];
    },
    totalPages() {
      if (this.addressCount > 0) {
        return Math.ceil(this.addressCount / 10);
      } else {
        return 0;
      }
    },
    loadingState() {
      return this.$store.getters.loadingState;
    },
  },
  watch: {
    page(value) {
      if (value) {
        this.panel = [];
      }
    },
  },
  methods: {
    toggleDrawer() {
      this.$store.dispatch('toggleDrawer', null, { root: true });
    },
    edit(id) {
      this.id = id;
      this.dialogVisible = true;
    },
    closeDialog() {
      (this.id = null), (this.dialogVisible = false);
    },
    setSearchTerm() {
      this.page = 1;
      this.$store.dispatch('address/setAddressSearchTerm', this.searchTerm, { root: true });
    },
    clearSearchTerm() {
      this.page = 1;
      this.$store.dispatch('address/setAddressSearchTerm', null, { root: true });
    },
  },
  beforeDestroy() {
    this.clearSearchTerm();
  },
};
</script>
<style scoped>
.confirm-text {
  font-size: 1rem;
}
.expansion-panel-header {
  padding: 6px 18px 6px 6px;
}
</style>
