import DeviceDetector from 'device-detector-js';
import * as firebase from 'firebase';

export const log = (payload) => {
  const deviceDetector = new DeviceDetector();
  const type = payload.type;
  const userId = payload.userId;
  const email = payload.email;
  const doc = { type, userId, email };

  if (type === 'login' || type === 'refresh') {
    doc.device = deviceDetector.parse(navigator.userAgent);
    doc.resolution = {
      width: window.innerWidth,
      height: window.innerHeight
    }
  } else if (type === 'login-error') {
    doc.errorCode = payload.errorCode;
    doc.errorMessage = payload.errorMessage;
  }
  const app = firebase.app();
  const functions = app.functions('europe-west3');
  const log = functions.httpsCallable('log');
  log(doc).catch((error) => console.log(`logger error: ${error}`))
}
