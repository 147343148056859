<template>
  <v-dialog persistent scrollable :value="visible" max-width="500">
    <v-form v-model="formValid" ref="form">
      <v-card v-if="!deleteEntry">
        <v-card-title>
          <span class="headline">{{ form.id ? $t('edit') : $t('add-new') }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12">
              <v-text-field
                dense
                :label="$t('naming')"
                required
                :rules="[rules.required]"
                v-model="form.data.name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                dense
                :label="$t('zip')"
                required
                :rules="[rules.required]"
                v-mask="mask.zip"
                v-model="form.data.zip"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="9">
              <v-text-field
                dense
                :label="$t('settlement')"
                required
                :rules="[rules.required]"
                v-model="form.data.city"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                dense
                :label="$t('address')"
                required
                :rules="[rules.required]"
                v-model="form.data.address"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-switch v-model="form.data.isSchool" :label="$t('school') + '?'"></v-switch>
            </v-col>
          </v-row>
          <v-row v-if="form.data.isSchool">
            <v-col cols="12">
              <v-autocomplete
                v-model="form.data.schoolDistrictId"
                :items="schoolDistricts"
                item-text="name"
                item-value="id"
                :label="$t('school-district')"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-list class="pa-0 mt-10">
            <v-list-item class="pa-0">
              <v-list-item-content class="pa-0">
                <v-list-item-title>{{ $t('contact-person') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-row>
            <v-col cols="12">
              <v-text-field dense :label="$t('name')" v-model="form.data.contactName"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                dense
                :label="$t('telephone')"
                v-mask="mask.telephone"
                v-model="form.data.contactTelephone"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                dense
                :label="$t('email')"
                :rules="[rules.email]"
                v-model="form.data.contactEmail"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field dense :label="$t('website')" v-model="form.data.url"></v-text-field>
            </v-col>
          </v-row>

          <v-list class="pa-0 mt-10">
            <v-list-item class="pa-0">
              <v-list-item-content class="pa-0">
                <v-list-item-title>{{ $t('note') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-row>
            <v-col cols="12">
              <v-textarea dense auto-grow rows="1" row-height="24" v-model="form.data.note"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text v-if="form.id" @click="deleteEntry = true">{{ $t('delete') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text :loading="loading" @click="save()">{{ $t('save') }}</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="deleteEntry">
        <v-card-text class="confirm-text">{{ $t('confirm-to-delete') }}</v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" :loading="deleting" text @click="remove()">{{ $t('delete') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deleteEntry = false">{{ $t('cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import * as firebase from 'firebase';
import { rules } from '../../helpers/validator-rules';
import { mask } from 'vue-the-mask';
export default {
  name: 'AdressDialog',
  props: ['visible', 'id'],
  directives: {
    mask,
  },
  data() {
    return {
      loading: false,
      deleting: false,
      form: {
        id: null,
        data: {
          name: null,
          zip: null,
          city: null,
          address: null,
          note: null,
          contactName: null,
          contactEmail: null,
          contactTelephone: null,
          isSchool: false,
          schoolDistrictId: null,
          deleted: false,
          url: null,
        },
      },
      formValid: false,
      deleteEntry: false,
      mask: {
        telephone: '+36 ## ### ####',
        zip: '####',
      },
      rules,
    };
  },
  computed: {
    address() {
      return this.id && this.$store.getters['address/addressById'](this.id);
    },
    schoolDistricts() {
      return this.$store.getters['schoolDistrict/schoolDistricts'];
    },
  },
  methods: {
    save() {
      if (this.formValid) {
        this.loading = true;
        if (this.form.id) {
          firebase
            .firestore()
            .collection('addresses')
            .doc(this.form.id)
            .set({ ...this.form.data, schoolDistrict: this.schoolDistrictById(this.form.data.schoolDistrictId), updateTime: new Date().toISOString() })
            .then(() => {
              this.loading = false;
              this.close();
            })
            .catch((error) => {
              this.loading = false;
              this.showSnackbar(error);
            });
        } else {
          firebase
            .firestore()
            .collection('addresses')
            .add({ ...this.form.data, schoolDistrict: this.schoolDistrictById(this.form.data.schoolDistrictId), createTime: new Date().toISOString(), updateTime: new Date().toISOString() })
            .then(() => {
              firebase.firestore().collection('stat').doc('collectionCount')
                .update({ addresses: firebase.firestore.FieldValue.increment(1) });

              this.loading = false;
              this.close();
            })
            .catch((error) => {
              this.loading = false;
              this.showSnackbar(error);
            });
        }
      } else {
        this.$refs.form.validate();
      }
    },
    remove() {
      this.deleting = true;
      firebase
        .firestore()
        .collection('addresses')
        .doc(this.form.id)
        firebase
          .firestore()
          .collection('addresses')
          .doc(this.form.id)
          .update({
            deleted: true,
            updateTime: new Date().toISOString(),
          })
          .then(() => {
            this.deleting = false;
            this.close();
          })
          .catch((error) => {
            this.deleting = false;
            this.showSnackbar(error);
          });
    },
    close() {
      this.$emit('close');
    },
    showSnackbar(text) {
      this.$store.dispatch('setSnackbar', { text }, { root: true });
    },
    schoolDistrictById(id) {
      return this.schoolDistricts && this.schoolDistricts.find((item) => item.id === id);
    },
  },
  created() {
    if (this.id) {
      const { id, ...data } = this.address;
      this.form.id = id;
      this.form.data = JSON.parse(JSON.stringify(data));
    }
  },
};
</script>
