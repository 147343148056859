import * as firebase from 'firebase';
import { readAllFromIndexedDB, writeToIndexedDB } from '@/services/indexedDB';
import { listener } from '@/services/firebase';

let unsubscribe1, unsubscribe2;
const usersSearchFields = ['lastname', 'firstname'];

export default {
  namespaced: true,
  state: {
    authUser: null,
    userRoles: [],
    users: [],
    usersLoading: false,
    userSearchTerm: null,
  },
  actions: {
    signin({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
          .then((response) => {
            // eslint-disable-next-line no-unused-vars
            const { uid, email, ...rest } = response;
            commit('user/setUser', { uid, email, userRoles: null, loginError: null }, { root: true });
            dispatch('setUserRole', null);
            resolve(response);
          })
          .catch((error) => {
            commit('user/setUser', { uid: null, email: null, loginError: error.code }, { root: true });
            reject(error);
          })
      });
    },
    resetPassword({ commit }, payload) {
      return new Promise((resolve, reject) => {
        firebase
          .auth()
          .sendPasswordResetEmail(payload.email)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      });
    },
    resetUser({ commit }) {
      commit('user/setUser', null, { root: true });
    },
    setUserRole({ commit }) {
      try {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((result) => {
            if (result.claims && result.claims.roles) {
              commit('setAuthUserRoles', result.claims.roles);
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    autoSignin({ commit, dispatch }, payload) {
      commit('user/setUser', { uid: payload.uid, email: payload.email, userRoles: null, loginError: null }, { root: true });
      dispatch('setUserRole', null);
    },
    signout({ commit, dispatch }) {
      dispatch('address/unsubscribe', null, { root: true });
      dispatch('event/unsubscribe', null, { root: true });
      dispatch('eventGroup/unsubscribe', null, { root: true });
      dispatch('eventType/unsubscribe', null, { root: true });
      dispatch('instrument/unsubscribe', null, { root: true });
      dispatch('role/unsubscribe', null, { root: true });
      dispatch('schoolDistrict/unsubscribe', null, { root: true });
      dispatch('user/unsubscribe', null, { root: true });
      firebase.auth().signOut();
      commit('user/setUser', null, { root: true });
    },
    async fetchUsers({ commit }) {
      commit('setUsersLoading', true);

      const { data: initialData, lastUpdateTime: initialLastUpdateTime } = await readAllFromIndexedDB('users');
      commit('addUsers', initialData);

      const collectionRef = firebase.firestore().collection('users');
      const query = collectionRef;

      const callback = async (snapshot) => {
        console.log('snap/usr', snapshot.docs.length);
        if (snapshot.docs.length === 0) {
          commit('setUsersLoading', false);
          return;
        };

        snapshot.docs.forEach(async (doc) => {
          await writeToIndexedDB('users', { id: doc.id, ...doc.data() });
        });
        const { data, lastUpdateTime } = await readAllFromIndexedDB('users');
        commit('addUsers', data);
        commit('setUsersLoading', false);
        unsubscribe1();
        unsubscribe1 = listener(query.where('updateTime', '>', lastUpdateTime), callback);
      }

      unsubscribe1 = listener(initialLastUpdateTime ? query.where('updateTime', '>', initialLastUpdateTime).orderBy('updateTime') : query, callback);
    },
    setUserSearchTerm({ commit }, payload) {
      commit('setUserSearchTerm', payload);
    },

    async fetchUserRoles({ commit }) {
      const { data: initialData, lastUpdateTime: initialLastUpdateTime } = await readAllFromIndexedDB('userRoles');
      commit('addUserRoles', initialData);

      const collectionRef = firebase.firestore().collection('userRoles');
      const query = collectionRef.orderBy('updateTime');

      const callback = async (snapshot) => {
        console.log('snap/urol', snapshot.docs.length);
        if (snapshot.docs.length === 0) {
          return;
        };

        snapshot.docs.forEach(async (doc) => {
          await writeToIndexedDB('userRoles', { id: doc.id, ...doc.data() });
        });
        const { data, lastUpdateTime } = await readAllFromIndexedDB('userRoles');
        commit('addUserRoles', data);
        unsubscribe2();
        unsubscribe2 = listener(lastUpdateTime ? query.where('updateTime', '>', lastUpdateTime) : query, callback);
      }

      unsubscribe2 = listener(initialLastUpdateTime ? query.where('updateTime', '>', initialLastUpdateTime) : query, callback);
    },
    setUserRole({ commit }) {
      try {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((result) => {
            if (result.claims && result.claims.roles) {
              commit('user/setAuthUserRoles', result.claims.roles, { root: true });
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    unsubscribe({ commit }) {
      unsubscribe1();
      unsubscribe2();
    }
  },
  mutations: {
    setUser(state, payload) {
      state.authUser = payload;
    },
    setAuthUserRoles(state, payload) {
      state.authUser.userRoles = payload;
    },
    addUser(state, payload) {
      const fullName = payload.lastname + ' ' + payload.firstname;
      state.users.push({ ...payload, fullName });
    },
    addUsers(state, payload) {
      state.users = payload.map(user => {
        const fullName = user.lastname + ' ' + user.firstname;
        return { ...user, fullName };
      });
    },
    setUserSearchTerm(state, payload) {
      state.userSearchTerm = payload ? payload.toString().toLowerCase() : null;
    },
    setUsersLoading(state, payload) {
      state.usersLoading = payload;
    },
    addUserRoles(state, payload) {
      state.userRoles = payload;
    },
  },
  getters: {
    orderedUsers(state) {
      return state.users?.sort((a, b) => a.fullName?.localeCompare(b.fullName)) ?? [];
    },
    users: (state, getters) => (getters.orderedUsers),
    usersExclDeleted: (state, getters) => (getters.orderedUsers.filter((user) => !user.deleted)),
    usersWithParticipantRoles: (state, getters) => {
      return getters.orderedUsers.filter((user) => user.userRoles?.some((role) => ['musician', 'performer'].includes(role)));
    },
    usersWithParticipantRolesExclDeleted: (state, getters, rootState, rootGetters) => {
      return rootGetters['user/usersExclDeleted']?.filter((user) => user.userRoles?.some((role) => ['musician', 'performer'].includes(role)));
    },
    authUser: (state) => (state.authUser),
    isAuthUserAnAdmin: (state) => (state.authUser?.userRoles?.includes('admin')),
    userInfo(state, getters, rootState, rootGetters) {
      return (
        state.users.length > 0 &&
        rootGetters['user/authUser'] &&
        rootGetters['user/authUser'].uid &&
        state.users.find((user) => user.id === rootGetters['user/authUser'].uid)
      );
    },
    usersLoading: (state) => (state.usersLoading),
    filteredUsersBySearchTerm: (state, getters, rootState, rootGetters) => {
      return rootGetters['user/usersWithParticipantRolesExclDeleted']?.filter((user) => {
        return state.userSearchTerm
          ? usersSearchFields.some((key) => {
            const string = user[key] ? user[key].toString().toLowerCase() : '';
            return string.length > 0 ? string.includes(state.userSearchTerm) : false;
          })
          : user;
      });
    },
    userById: (state) => (id) => (state.users.find((item) => item.id === id)),
    usersSubset: (state, getters, rootState, rootGetters) => (page) => {
      const start = (page - 1) * 10;
      const stop = start + 10;
      return rootGetters['user/filteredUsersBySearchTerm'] ? rootGetters['user/filteredUsersBySearchTerm'].slice(start, stop) : [];
    },
    usersCount(state, getters, rootState, rootGetters) {
      return rootGetters['user/filteredUsersBySearchTerm'] ? rootGetters['user/filteredUsersBySearchTerm'].length : 0;
    },
    userRoles(state, getters, rootState, rootGetters) {
      const isAuthUserAnAdmin = rootGetters['user/isAuthUserAnAdmin'];
      if (isAuthUserAnAdmin) return state.userRoles;
      return state.userRoles.filter((item) => item.id !== 'admin');
    },
    participantRoles(state, getters, rootState, rootGetters) {
      return state.userRoles.filter((item) => ['musician', 'performer'].includes(item.id));
    },
  }
}
