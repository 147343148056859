<template>
  <div>
    <v-app-bar flat :clipped-left="$vuetify.breakpoint.lgAndUp" app color="blue darken-3" dark>
      <v-app-bar-nav-icon @click.stop="toggleDrawer()" />
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">{{ $t('edit-profile') }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-form v-model="nameFormValid" ref="nameForm">
      <v-container>
        <v-row class="mx-3 pb-4">
          <v-col cols="12" md="4">
            <h3>{{ $t('name') }}</h3>
            <v-row class="mt-4">
              <v-col cols="12" md="6">
                <v-text-field
                  :label="$t('last-name')"
                  required
                  :rules="[rules.required]"
                  v-model="lastname"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :label="$t('first-name')"
                  required
                  :rules="[rules.required]"
                  v-model="firstname"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn
              outlined
              class="mt-3"
              color="primary"
              @click.stop="saveName()"
              :disabled="!newFirstname && !newLastname"
              :loading="savingName"
              >{{ $t('save') }}</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-divider class="my-3"></v-divider>

    <v-form v-model="emailFormValid" ref="emailForm">
      <v-container>
        <v-row class="mx-3 py-4">
          <v-col cols="12" md="4">
            <h3>{{ $t('email-address') }}</h3>
            <v-row class="mt-4">
              <v-col cols="12">
                <v-text-field
                  :label="$t('email-address')"
                  required
                  :rules="[rules.required, rules.email]"
                  v-model="email"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  :label="$t('current-password')"
                  type="password"
                  v-model="password"
                  required
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn
              outlined
              class="mt-3"
              color="primary"
              @click.stop="saveEmail()"
              :disabled="!(newEmail && password)"
              :loading="savingEmail"
              >{{ $t('save') }}</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-divider class="my-3"></v-divider>

    <v-form v-model="passwordFormValid" ref="passwordForm">
      <v-container>
        <v-row class="mx-3 pt-4 mb-12">
          <v-col cols="12" md="4">
            <h3>{{ $t('password') }}</h3>
            <v-row class="mt-4">
              <v-col cols="12">
                <v-text-field
                  :label="$t('current-password')"
                  type="password"
                  v-model="currentPassword"
                  required
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  :label="$t('new-password')"
                  type="password"
                  v-model="newPassword"
                  counter
                  :rules="[rules.required, rules.minChars]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  :label="$t('new-password-again')"
                  type="password"
                  v-model="newPasswordRepeated"
                  :rules="[passwordConfirmationRule]"
                  counter
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn
              outlined
              class="mt-3"
              color="primary"
              @click.stop="savePassword()"
              :disabled="!(currentPassword && newPassword)"
              :loading="savingPassword"
              >{{ $t('save') }}</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import * as firebase from 'firebase';
import { rules } from '@/helpers/validator-rules';
export default {
  name: 'ProfileView',
  data() {
    return {
      nameFormValid: null,
      newLastname: null,
      newFirstname: null,
      savingName: false,

      emailFormValid: null,
      newEmail: null,
      password: null,
      savingEmail: false,

      passwordFormValid: null,
      currentPassword: null,
      newPassword: null,
      newPasswordRepeated: null,
      savingPassword: false,

      loading: false,
      uid: null,

      rules,
      formValid: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters['user/authUser'];
    },
    userInfo() {
      return this.$store.getters['user/userInfo'];
    },
    lastname: {
      get() {
        return this.$store.getters['user/userInfo'] && this.$store.getters['user/userInfo'].lastname;
      },
      set(value) {
        this.newLastname = value;
      },
    },
    firstname: {
      get() {
        return this.$store.getters['user/userInfo'] && this.$store.getters['user/userInfo'].firstname;
      },
      set(value) {
        this.newFirstname = value;
      },
    },
    email: {
      get() {
        return this.$store.getters['user/authUser'] && this.$store.getters['user/authUser'].email;
      },
      set(value) {
        this.newEmail = value;
      },
    },
    passwordConfirmationRule() {
      if (this.newPassword) {
        return this.newPassword === this.newPasswordRepeated || 'A jelszavak nem egyeznek meg';
      } else {
        return true;
      }
    },
  },
  methods: {
    toggleDrawer() {
      this.$store.dispatch('toggleDrawer', null, { root: true });
    },
    saveName() {
      if (this.nameFormValid) {
        this.savingName = true;
        if (
          (this.newFirstname && this.firstname !== this.newFirstname) ||
          (this.newLastname && this.lastname !== this.newLastname)
        ) {
          firebase
            .firestore()
            .collection('users')
            .doc(this.user.uid)
            .set({
              ...this.userInfo,
              firstname: this.newFirstname ? this.newFirstname : this.firstname,
              lastname: this.newLastname ? this.newLastname : this.lastname,
              updateTime: new Date().toISOString(),
            })
            .then(() => {
              this.savingName = false;
              this.showSnackbar('Név frissítve');
              this.$refs.nameForm.resetValidation();
              this.newFirstname = null;
              this.newLastname = null;
            })
            .catch((error) => {
              this.savingName = false;
              this.showSnackbar(error.code);
            });
        }
      } else {
        this.$refs.nameForm.validate();
      }
    },
    saveEmail() {
      if (this.emailFormValid) {
        this.savingEmail = true;
        if (this.password && this.newEmail) {
          this.reauthenticate(this.password)
            .then(() => {
              const user = firebase.auth().currentUser;
              user
                .updateEmail(this.newEmail)
                .then(() => {
                  firebase
                    .firestore()
                    .collection('users')
                    .doc(this.user.uid)
                    .set({
                      ...this.userInfo,
                      email: this.newEmail ? this.newEmail : this.email,
                      updateTime: new Date().toISOString(),
                    })
                    .then(() => {
                      this.savingEmail = false;
                      this.showSnackbar('Email frissítve');
                      this.$refs.emailForm.resetValidation();
                      this.newEmail = null;
                      this.password = null;
                    })
                    .catch((error) => {
                      this.savingEmail = false;
                      this.showSnackbar(error.code);
                    });
                })
                .catch((error) => {
                  this.savingEmail = false;
                  this.showSnackbar(error.code);
                });
            })
            .catch((error) => {
              this.savingEmail = false;
              this.showSnackbar(error.code);
            });
        }
      } else {
        this.$refs.nameForm.validate();
      }
    },
    savePassword() {
      if (this.passwordFormValid) {
        this.savingPassword = true;
        if (this.currentPassword && this.newPassword && this.newPasswordRepeated) {
          this.reauthenticate(this.currentPassword)
            .then(() => {
              const user = firebase.auth().currentUser;
              user
                .updatePassword(this.newPassword)
                .then(() => {
                  this.savingPassword = false;
                  this.showSnackbar('Jelszó frissítve');
                  this.$refs.passwordForm.resetValidation();
                  this.currentPassword = null;
                  this.newPassword = null;
                  this.newPasswordRepeated = null;

                  firebase
                    .firestore()
                    .collection('users')
                    .doc(this.user.uid)
                    .set({
                      ...this.userInfo,
                      defaultPasswordChanged: true,
                      updateTime: new Date().toISOString(),
                    });
                })
                .catch((error) => {
                  this.savingPassword = false;
                  if (error.code == 'auth/weak-password') {
                    this.showSnackbar();
                  }
                });
            })
            .catch((error) => {
              this.savingPassword = false;
              this.showSnackbar(error.code);
            });
        }
      } else {
        this.$refs.nameForm.validate();
      }
    },
    reauthenticate(currentPassword) {
      const user = firebase.auth().currentUser;
      const cred = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
      return user.reauthenticateWithCredential(cred);
    },
    showSnackbar(text) {
      this.$store.dispatch('setSnackbar', { text, duration: 0 }, { root: true });
    },
  },
};
</script>
